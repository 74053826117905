import HttpMethod from '@/consts/httpMethod';
import { processRequest, processErrorResponse, processSuccessResponse } from '@/helpers';

export default {
  async getOrders(params) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: '/v1/orders',
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, { params });
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async completeOrder(id) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/orders/${id}:complete`,
      method: HttpMethod.POST
    };

    try {
      await processRequest(ep);
      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async markSpam(id) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/orders/${id}:mark-spam`,
      method: HttpMethod.POST
    };

    try {
      await processRequest(ep);
      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async sendTrackingCode(id, trackingCode, carrier = 'VNPOST') {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/orders/${id}:ship`,
      method: HttpMethod.POST
    };

    try {
      await processRequest(ep, { data: { trackingCode, carrier } });
      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async sendConfirmEmail(id) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/orders/${id}:send-confirmation`,
      method: HttpMethod.POST
    };

    try {
      await processRequest(ep, {});
      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },
};
