<template>
  <footer>
    <p>XOI Admin Panel © 2021</p>
    <p>Powered by <a href="https://www.xoi.vn" target="_blank">XOI Development &amp; Designs</a>.</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
  setup() {
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

footer {
  font-size: 10px;
  line-height: 16px;

  p {
    margin: 0;
  }

  a {
    color: $blue;
  }
}
</style>
