import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import loader from '@/components/Loader/store';
import flash from '@/components/FlashMessage/store';

import users from '@/views/Users/store';
import stories from '@/views/Stories/store';
import storyPieces from '@/views/StoryPieces/store';
import products from '@/views/Products/store';
import orders from '@/views/Orders/store';

import user from './user';
import app from './app';

const STORE_KEY = process.env.VUE_APP_STORE_KEY;
const namespace = 'xoi-web-admin';
const key = `${namespace}.${STORE_KEY}`;

const vuexPersistLocal = createPersistedState({
  key,
  storage: window.localStorage,
  paths: ['user', 'app']
});

const vuexPersistSession = createPersistedState({
  key,
  storage: window.sessionStorage,
  paths: []
});

const plugins = [
  vuexPersistLocal,
  vuexPersistSession
];

const store = createStore({
  plugins,
  modules: {
    app,
    user,
    loader,
    flash,
    users,
    stories,
    storyPieces,
    products,
    orders
  }
});

export default store;
