import { FLASH_TIMEOUT } from '@/consts';

let activeTimeout;

const mutations = {
  setMessage(state, msg) {
    if (activeTimeout) clearTimeout(activeTimeout);
    state.message = msg;
  },

  setType(state, type) {
    state.type = type;
  },

  setActiveStatus(state, val) {
    state.isActive = val;

    if (val) {
      if (activeTimeout) clearTimeout(activeTimeout);

      activeTimeout = setTimeout(() => {
        state.isActive = false;
      }, FLASH_TIMEOUT);
    }
  }
};

export default mutations;
