import HttpMethod from '@/consts/httpMethod';
import { processRequest, processErrorResponse } from '@/helpers';

export default {
  async changePassword(password, newPassword) {
    const ep = {
      baseURL: process.env.VUE_APP_AUTH_API_ROOT,
      url: '/v1/me/password',
      method: HttpMethod.PUT
    };

    try {
      await processRequest(ep, { data: {
        password,
        newPassword
      } });

      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
