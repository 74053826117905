<template>
  <div id="stories-list">
    <div class="title">
      <font-awesome-icon :icon="faScroll" class="icon" />
      <h1>{{ t('STORIES') }}</h1>

      <router-link :to="applyParams(PATHS.STORY_EDITOR, '')">
        <div class="add-btn">
          <cross white />
        </div>
      </router-link>
    </div>

    <div class="list" ref="listRef">
      <div class="row">
        <div
          class="col col-xs-12 col-md-6 col-lg-4"
          v-for="(col, i) in itemsGrid"
          :key="i"
        >
          <div
            v-for="item in itemsGrid[i]"
            :key="item.id"
            class="item"
          >
            <div class="box">
              <div class="controls">
                <toggle-input
                  v-if="canPublish"
                  :value="item.published"
                  @click="toggleStory(item)"
                />
                <router-link :to="applyParams(PATHS.STORY_EDITOR, item.id)">
                  <font-awesome-icon :icon="faPenAlt" class="icon" />
                </router-link>
                <font-awesome-icon :icon="faTrashAlt" class="icon" @click="removeStory(item.id)" v-if="canRemove" />
              </div>

              <router-link :to="applyParams(PATHS.STORY_EDITOR, item.id)">
                <h3>{{ item.title }}</h3>
                <div class="img-holder" v-if="!!item.image">
                  <img :src="item.image.url" :alt="item.title" />
                </div>
              </router-link>
              <p>{{ item.description }}</p>
              <div class="date">{{ moment(item.updatedAt).format(`${locale === 'en' ? 'DD MMM, YYYY' : 'DD [tháng] MM, YYYY'} | HH:ss`) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, onUnmounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import moment from 'moment';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faScroll, faTrashAlt, faPenAlt } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import Cross from '@/components/Cross';
import ToggleInput from '@/components/ToggleInput';

import PATHS from '@/consts/paths';
import PERMISSIONS from '@/consts/permissionCodes';
import { applyParams } from '@/helpers';
import { hasPermissions } from '@/helpers/permission';

import StoriesService from './StoriesService';

export default {
  name: 'StoriesList',
  components: {
    FontAwesomeIcon,
    Cross,
    ToggleInput
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const listRef = ref(null);
    const filters = computed(() => store.state.stories.filters);

    const canPublish = computed(() => hasPermissions([PERMISSIONS.ADMIN_MANAGE_STORY]));
    const canRemove = computed(() => hasPermissions([PERMISSIONS.ADMIN_MANAGE_STORY]));

    const calculateItemHeightScore = (item) => {
      let h = 0;
      if (item?.image) h += 300;
      if (item?.description) h += item.description.length;

      return h;
    };

    const items = computed(() => store.state.stories.items);
    const itemsGrid = computed(() => {
      let n = 3;
      if (store.state.app.isTablet) {
        n = 2;
      } else if (store.state.app.isMobile) {
        n = 1;
      }

      const stacks = Array(n).fill().map(() => []);
      const stackHeights = Array(n).fill(0);

      for (let i = 0; i < items.value.length; i += 1) {
        const minStack = stackHeights.indexOf(Math.min(...stackHeights));
        stacks[minStack].push({ ...items.value[i] });
        stackHeights[minStack] += calculateItemHeightScore(items.value[i]);
      }

      return stacks;
    });

    const removeStory = async (id) => {
      try {
        await StoriesService.removeStory(id);
        store.dispatch('stories/getStories', { append: false });
        store.dispatch('flash/info', 'STORY_SUCCESSFULLY_REMOVED');
      } catch(e) {
        store.dispatch('flash/error', e.message);
      }
    };

    const toggleStory = async (dish) => {
      const { id } = dish;

      try {
        if (dish.published) {
          await StoriesService.toggleStory(id, false);
          store.dispatch('flash/info', 'STORY_SUCCESSFULLY_UNPUBLISHED');
        } else {
          await StoriesService.toggleStory(id, true);
          store.dispatch('flash/info', 'STORY_SUCCESSFULLY_PUBLISHED');
        }
        store.dispatch('stories/getStories', { append: false });
      } catch(e) {
        store.dispatch('flash/error', e.message);
      }
    };

    const watchLoadMore = () => {
      if (!listRef.value) return;

      const y = window.scrollY;
      const h = $(listRef.value).height();

      if (y + 200 > h) {
        store.dispatch('stories/applyFilters', {
          page: filters.value.page + 1
        });
      }
    };

    watch(filters, () => {
      store.dispatch('stories/getStories', { append: false });
    });

    onMounted(() => {
      store.dispatch('stories/getStories', { append: false });

      $(window).on('scroll', watchLoadMore);
    });

    onUnmounted(() => {
      $(window).off('scroll', watchLoadMore);
    });

    return {
      t,
      faScroll,
      faPlus,
      itemsGrid,
      PATHS,
      faTrashAlt,
      faPenAlt,
      removeStory,
      applyParams,
      toggleStory,
      canPublish,
      canRemove,
      moment,
      locale,
      listRef
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

#stories-list {
  .list {
    .item {
      .box {
        .controls {
          .toggle-input {
            .toggle-wrapper {
              &.active {
                background-color: $blue;
              }
            }
          }
        }

        .date {
          color: $grey;
          margin-top: 10px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
