<template>
  <div id="admin">
    <router-view />

    <loader />
    <flash-message />
  </div>
</template>

<script>
import { computed, watch, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import $ from 'jquery';

import PATHS from '@/consts/paths';

import Loader from '@/components/Loader';
import FlashMessage from '@/components/FlashMessage';

export default {
  name: 'AppAdmin',
  components: {
    Loader,
    FlashMessage
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isHeadless = computed(() => !!route.meta.headless);

    const store = useStore();
    const isLoggedIn = computed(() => store.getters['user/isLoggedIn']);

    const watchScreenSize = () => {
      if (process.browser) {
        const w = window.innerWidth;
        store.dispatch('app/setSize', w);
      }
    };

    watch(isLoggedIn, (val) => {
      if (!val) {
        const { requiresAuth } = route.meta;
        if (requiresAuth) {
          router.push(PATHS.LOGIN);
        }
      }
    });

    onMounted(() => {
      $(window).on('resize', watchScreenSize);
    });

    onUnmounted(() => {
      $(window).off('resize', watchScreenSize);
    });

    return {
      isHeadless
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/fonts';
@import '~@/assets/scss/colors';
@import '~@/assets/scss/dimens';

body {
  font-family: Nunito, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $black;
  overflow-x: hidden;
  box-sizing: border-box;
}

.container {
  max-width: 1140px !important;
}

[clear] {
  clear: both;
}

.title {
  margin-bottom: 30px;

  .icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    vertical-align: middle;
    color: $blue;
    transition: all .2s ease-in-out;
  }

  h1 {
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    margin: 0;
  }

  .add-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $blue;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
    text-align: center;
    padding-top: 14px;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: $pink;
    }

    svg {
      color: $white;
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }
}

.list {
  .item {
    margin-bottom: 15px;

    .box {
      position: relative;
      border: 1px solid $alto;
      border-radius: 4px;
      padding: 15px;
      padding-bottom: 18px;

      .controls {
        text-align: right;
        padding: 7px 15px;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;

        .icon {
          margin-left: 10px;
          vertical-align: middle;
          color: $pink;
          transition: all .2s ease-in-out;
          cursor: pointer;
          font-size: 20px;

          &:hover {
            color: $blue;
          }
        }
      }

      .img-holder {
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        margin-bottom: 7px;
        border-radius: 2px;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      a {
        color: inherit;
        text-decoration: none;
        transition: all .2s ease-in-out;

        &:hover {
          color: $blue;
        }
      }

      h3 {
        font-size: 24px;
        font-family: 'Source Serif Pro', 'Times New Roman', Times, serif;
        font-weight: bold;
        cursor: pointer;
        margin-top: 25px;

        &.inactive {
          color: $alto;
        }
      }

      p {
        margin: 0;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
      }
    }
  }
}

.editor {
  margin-bottom: 50px;

  > .input {
    margin-bottom: 15px;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  .text-input, .autocomplete-input {
    width: 300px;
    display: block;
  }

  .textarea-input {
    width: 440px;
    display: block;
  }

  .img-holder {
    width: 300px;
    height: 300px;
    position: relative;
    margin-bottom: 7px;
    border-radius: 2px;
    overflow: hidden;

    @media (max-width: 359px) {
      max-width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .remove {
      position: absolute;
      top: 7px;
      right: 7px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      color: $white;
      background-color: rgba(0, 0, 0, .75);
      border-radius: 2px;
      transition: all .2s ease-in-out;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      &:hover {
        background-color: $blue;
      }
    }
  }

  .img-holder-list {
    .img-holder {
      display: inline-block;
      margin-right: 12px;
      width: 180px;
      height: 180px;
    }
  }

  .file-url {
    margin-bottom: 10px;

    small {
      vertical-align: middle;
    }

    .remove {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      color: $white;
      background-color: rgba(0, 0, 0, .75);
      border-radius: 2px;
      transition: all .2s ease-in-out;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      &:hover {
        background-color: $blue;
      }
    }
  }
}
</style>
