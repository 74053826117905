import ERROR_CODES from '@/consts/errorCodes';

export default class IncorrectPassword extends Error {
  static code = ERROR_CODES.AUTH_INCORRECT_PASSWORD;

  constructor(message) {
    super(message || 'Incorrect password.');
    this.code = ERROR_CODES.AUTH_INCORRECT_PASSWORD;
  }
}
