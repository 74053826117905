export const INIT_STATE = {
  filters: {
    page: 1,
    pageSize: 20
  },
  items: [],
  total: 0,
};

const mutations = {
  applyFilters(state, filters) {
    state.filters = {
      ...state.filters,
      ...filters
    };
  },

  setItems(state, { items, total }) {
    state.items = [...items];
    state.total = total;
  },

  reset(state) {
    Object.keys(INIT_STATE).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(INIT_STATE, key)) {
        state[key] = INIT_STATE[key];
      }
    });
  }
};

export default mutations;
