<template>
  <div id="change-password">
    <form @submit="submit">
      <text-input
        :placeholder="t('CURRENT_PASSWORD')"
        type="password"
        v-model:value="currentPassword"
        required
        :label="t('ENTER_YOUR_CURRENT_PASSWORD')"
        ref="currentPasswordInput"
      />

      <text-input
        :placeholder="t('NEW_PASSWORD')"
        type="password"
        v-model:value="newPassword"
        required
        :label="t('ENTER_YOUR_NEW_PASSWORD')"
        ref="newPasswordInput"
      />

      <text-input
        :placeholder="t('CONFIRM_PASSWORD')"
        type="password"
        v-model:value="confirmPassword"
        required
        :label="t('CONFIRM_YOUR_NEW_PASSWORD')"
        ref="confirmPasswordInput"
        :validate="validateNewPassword"
      />

      <primary-button :text="t('CHANGE_PASSWORD')" type="submit" />
    </form>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import TextInput from '@/components/TextInput';
import PrimaryButton from '@/components/PrimaryButton';

import changePasswordServices from './ChangePasswordServices';

export default {
  name: 'Home',
  components: {
    TextInput,
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const currentPassword = ref('');
    const currentPasswordInput = ref(null);
    const newPassword = ref('');
    const newPasswordInput = ref(null);
    const confirmPassword = ref('');
    const confirmPasswordInput = ref(null);

    const changePassword = async () => {
      const inputs = [currentPasswordInput.value, newPasswordInput.value, confirmPasswordInput.value];

      let valid = true;

      inputs.forEach((input) => {
        input.validateInput();
        valid = valid && input.isValid;
      });

      if (valid) {
        try {
          await changePasswordServices.changePassword(currentPassword.value, newPassword.value);
          await store.dispatch('user/logout');
          store.dispatch('flash/info', 'CHANGE_PASSWORD_SUCCESS');
        } catch(e) {
          store.dispatch('flash/error', 'CHANGE_PASSWORD_ERROR');
        }
      }
    };

    const submit = (e) => {
      e.preventDefault();
      changePassword();
    };

    const validateNewPassword = (val) => {
      if (val !== newPassword.value) {
        return 'NEW_PASSWORD_IS_NOT_A_MATCH';
      }

      return '';
    };

    watch(newPassword, () => {
      confirmPasswordInput.value.validateInput();
    });

    return {
      t,
      currentPassword,
      currentPasswordInput,
      newPassword,
      newPasswordInput,
      confirmPassword,
      confirmPasswordInput,
      submit,
      validateNewPassword
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import '~@/assets/scss/dimens';

#change-password {
  .text-input {
    display: block;
    max-width: 320px;
    text-align: left;
    margin-bottom: 10px;
  }

  .primary-button {
    display: block;
    width: 320px;
    margin-top: 20px;
  }
}
</style>
