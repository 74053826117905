import HttpMethod from '@/consts/httpMethod';
import { processRequest, processErrorResponse, processSuccessResponse } from '@/helpers';

export default {
  async createProduct(data) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: '/v1/products',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data });
      const dish = processSuccessResponse(res);
      return dish;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async updateProduct(id, data) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/products/${id}`,
      method: HttpMethod.PUT
    };

    try {
      const res = await processRequest(ep, { data });
      const dish = processSuccessResponse(res);
      return dish;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getProduct(id) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/products/${id}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      const dish = processSuccessResponse(res);
      return dish;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
