export default {
  UNKNOWN: -1,
  INVALID_RESPONSE: -2,
  AUTH_INVALID_TOKEN: 9000,
  AUTH_USER_NOT_FOUND: 9001,
  AUTH_INCORRECT_PASSWORD: 9002,
  AUTH_INVALID_REFRESH_TOKEN: 9003,

  DISH_EXISTS: 1000
};
