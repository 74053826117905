<template>
  <header>
    <logo />
    <div class="right">
      <div class="profile">
        <div class="name">{{ fullName }}</div>
        <div class="picture">
          <img :src="profilePicture" :alt="fullName" />
        </div>
      </div>

      <transition name="fade">
        <router-link :to="PATHS.HOME" v-show="!isHome">
          <primary-button :text="t('ADMIN_PANEL')" />
        </router-link>
      </transition>
      <router-link :to="PATHS.CHANGE_PASSWORD">
        <primary-button :text="t('CHANGE_PASSWORD')" />
      </router-link>
      <primary-button :text="t('LOG_OUT')" @click="() => logout()" />
      <primary-button :text="locale.toUpperCase()" @click="() => toggleLocale()" class="locale" />
    </div>
    <div clear />
  </header>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import i18n from '@/translation';
import PATHS from '@/consts/paths';

import Logo from '@/components/Logo';
import PrimaryButton from '@/components/PrimaryButton';

export default {
  name: 'Header',
  components: {
    Logo,
    PrimaryButton
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const route = useRoute();

    const isHome = computed(() => route.path === PATHS.HOME);
    const firstName = computed(() => store.state.user.profile.firstName);
    const lastName = computed(() => store.state.user.profile.lastName);
    const profilePicture = computed(() => store.state.user.profile.picture);

    const fullName = computed(() => `${firstName.value} ${lastName.value}`);

    const logout = () => {
      store.dispatch('user/logout');
    };

    const toggleLocale = () => {
      if (locale.value === 'en') {
        i18n.global.locale.value = 'vi';
      } else {
        i18n.global.locale.value = 'en';
      }
    };

    return {
      t,
      logout,
      PATHS,
      isHome,
      profilePicture,
      fullName,
      locale,
      toggleLocale
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import '~@/assets/scss/dimens';

header {
  display: flex;
  margin-bottom: 50px;

  .logo {
    width: 100px;
    color: $blue;

    @media (max-width: 359px) {
      width: 100px;
    }
  }

  .right {
    height: 100%;
    align-self: flex-end;
    margin-left: auto;
    text-align: right;

    .profile {
      margin-bottom: 10px;
      padding-right: 3px;

      .name {
        display: inline-block;
        margin-right: 10px;
        font-weight: 500;
      }

      .picture {
        display: inline-block;
        width: 48px;
        height: 48px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .primary-button {
      margin-left: 15px;

      &.locale {
        padding: 0;
        width: 36px;
        background-color: $black;
      }
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .2s ease;
    }

    .fade-enter-from, .fade-leave-to {
      opacity: 0;
    }

    @media (max-width: 767px) {
      text-align: right;

      .primary-button {
        margin: 0;
        margin-left: auto;
        display: block;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
