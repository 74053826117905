import { createI18n } from 'vue-i18n';

import storyEditor from '@/views/StoryEditor/translations';
import storyPieceEditor from '@/views/StoryPieceEditor/translations';

import productEditor from '@/views/ProductEditor/translations';

import auth from './auth';

import en from './en.json';
import vi from './vi.json';

const messages = {
  en: {
    ...en,
    ...auth.en,
    ...storyEditor.en,
    ...storyPieceEditor.en,
    ...productEditor.en
  },
  vi: {
    ...vi,
    ...auth.vi,
    ...storyEditor.vi,
    ...storyPieceEditor.vi,
    ...productEditor.vi
  }
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  messages
});

export default i18n;
