import { TweenMax } from 'gsap';
import axios from 'axios';

import * as errors from '@/errors';

export const scrollToTop = () => {
  if (process.browser) {
    TweenMax.to(window, .3, {
      scrollTo: 0
    });
  }
};

export const processRequest = (ep, config, showLoader = true) => {
  const { method, url, baseURL } = ep;

  return axios({
    headers: { 'Content-Type': 'application/json' },
    baseURL,
    method,
    url,
    ...config,
    showLoader
  });
};

export const processSuccessResponse = (res = {}) => {
  const data = res?.data?.data;
  const { InvalidResponse } = errors;

  if (!data) {
    throw new InvalidResponse();
  }

  return data;
};

export const processErrorResponse = (res = {}) => {
  const error = res?.data?.error;
  const { InvalidResponse, UnknownError } = errors;

  if (!error) {
    throw new InvalidResponse();
  }

  const { code, message } = error;

  Object.keys(errors).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(errors, key)) {
      if (code === errors[key].code) {
        throw new errors[key](message);
      }
    }
  });

  throw new UnknownError(message || 'Unknown Error');
};

export const normalizeText = (str) => {
  let text = str;

  text = text.replace(/[ẵẳẫẩắằăặầấậạáàâãªäả]/g, 'a');
  text = text.replace(/[ẴẲẪẨẮẰĂẶẤẦÂẬẠÁÀÂÃÄẢ]/g, 'A');
  text = text.replace(/[ĨỈỊÍÌÎÏ]/g, 'I');
  text = text.replace(/[ĩỉịíìîï]/g, 'i');
  text = text.replace(/[ỸỶỴÝỲ]/g, 'Y');
  text = text.replace(/[ỹỷỵýỳ]/g, 'y');
  text = text.replace(/[ễểếềêệẹéèêëẻẽ]/g, 'e');
  text = text.replace(/[ỄỂẾỀÊỆẸÉÈÊËẺẺ]/g, 'E');
  text = text.replace(/[ỗổốồôộọóòôốồõºöỏơớờởợỡ]/g, 'o');
  text = text.replace(/[ỖỔỐỒÔỘỌÓÒÔỐỒÕÖỎƠỚỜỞ]/g, 'O');
  text = text.replace(/[ưữửựừứũủụúùûü]/g, 'u');
  text = text.replace(/[ƯỮỬỰỪỨŨỦỤÚÙÛÜ]/g, 'U');
  text = text.replace(/[’‘‹›‚]/g, '\'');
  text = text.replace(/[“”«»„]/g, '"');
  text = text.replace(/–/g, '-');
  text = text.replace(/ç/g, 'c');
  text = text.replace(/Ç/g, 'C');
  text = text.replace(/ñ/g, 'n');
  text = text.replace(/Ñ/g, 'N');
  text = text.replace(/đ/g, 'd');
  text = text.replace(/Đ/g, 'D');
  text = text.replace(/\s+/g, ' ');
  text = text.replace(/[^a-zA-Z0-9 ]+/g, ' ');

  text = text.toLowerCase();

  return text;
};

export const slugToUpperSnake = (str) => {
  let text = str;

  text = text.toUpperCase();
  text = text.replace(/-/g, '_');

  return text;
};

export const applyParams = (...args) => {
  let route = args[0] || '';

  let i = 1;
  while (i < args.length) {
    route = route.replace(/:[a-zA-Z-_]+\??/, args[i]);
    i += 1;
  }

  return route;
};

export const getBase64 = (file) => (new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
}));

export const isSafari = () => (
  navigator.vendor
  && (navigator.vendor.indexOf('Apple') > -1)
  && navigator.userAgent
  && (navigator.userAgent.indexOf('CriOS') < 0)
  && (navigator.userAgent.indexOf('FxiOS') < 0)
);

export const addCommas = (str_, comma_) => {
  if (!str_) return 0;
  let str = str_.toString();

  while (str[0] === '0') str = str.substring(1, str.length);

  const comma = comma_ || ',';

  let s = str;
  let tail = '';

  const dot = str.indexOf(comma === ',' ? '.' : ',');

  if ((dot >= 0) && (dot !== str.length - 1)) {
    tail = str.substring(dot + 1, str.length);

    if (comma === ',') {
      tail = `.${tail}`;
    } else {
      tail = `,${tail}`;
    }

    s = str.substring(0, dot);
  }

  let signed = false;
  if (s[0] === '-') {
    signed = true;
  }

  s = s.replace(/\D/g, '');

  if (s.length < 4) return `${s}${tail}`;

  let temp = '';
  let n = 0;

  for (let i = s.length - 1; i >= 0; i -= 1) {
    temp = `${s[i]}${temp}`;
    n += 1;
    if ((n % 3 === 0) && (i > 0)) temp = `${comma}${temp}`;
  }
  return `${signed ? '-' : ''}${temp}${tail}`;
};

export const removeCommas = (str_, comma_) => {
  if (!str_) return 0;
  let str = str_.toString();

  const comma = comma_ || ',';

  str = str.replaceAll(comma, '');
  if (comma === '.') {
    str = str.replace(',', '.');
  }

  return parseFloat(str, 10);
};

export default {};
