<template>
  <div
    class="wysiwyg-input input"
    :class="{
      disabled
    }"
  >
    <div class="editor-menu">
      <div class="item" @click="bold()">
        <font-awesome-icon :icon="faBold" class="icon" />
      </div>
      <div class="item" @click="italic()">
        <font-awesome-icon :icon="faItalic" class="icon" />
      </div>
      <div class="item" @click="underline()">
        <font-awesome-icon :icon="faUnderline" class="icon" />
      </div>
      <div class="item" @click="alignLeft()">
        <font-awesome-icon :icon="faAlignLeft" class="icon" />
      </div>
      <div class="item" @click="alignCenter()">
        <font-awesome-icon :icon="faAlignCenter" class="icon" />
      </div>
      <div class="item" @click="alignRight()">
        <font-awesome-icon :icon="faAlignRight" class="icon" />
      </div>
      <div class="item" @click="alignJustify()">
        <font-awesome-icon :icon="faAlignJustify" class="icon" />
      </div>
    </div>

    <iframe class="editor" ref="input"></iframe>

    <textarea
      v-bind="$attrs"
      :value="value"
      v-on="inputListeners"
      ref="swap"
    />

    <label v-if="!!label">{{ label }} <span v-if="required && !!label">*</span></label>
    <div v-if="!!validateMessage" class="red">{{ validateMessage }}</div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import validator from 'validator';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBold, faItalic, faUnderline, faAlignLeft, faAlignRight, faAlignCenter, faAlignJustify } from '@fortawesome/pro-duotone-svg-icons';

import { PASSWORD_MIN_LENGTH } from '@/consts';

export default {
  name: 'WysiwygInput',
  components: {
    FontAwesomeIcon
  },
  props: {
    label: String,
    value: {
      type: [String, Number],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: () => ''
    },
    format: {
      type: Function,
      default: (val) => val
    },
    disabled: Boolean
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const validateMessage = ref('');
    const isValid = ref(false);
    const input = ref(null);
    const swap = ref(null);

    const iframeDoc = computed(() => input.value?.contentDocument);

    const alert = (msg) => {
      validateMessage.value = msg;
    };

    const validateInput = () => {
      const val = props.value;

      if ((props.required) && !val) {
        alert(t('REQUIRED_FIELD'));
        return false;
      }

      if ((props.type === 'email') && !(val && validator.isEmail(val))) {
        alert(t('INVALID_EMAIL'));
        return false;
      }

      if ((props.type === 'password') && (val.length < PASSWORD_MIN_LENGTH)) {
        alert(t('PASSWORD_MUST_BE_AT_LEAST', { min: PASSWORD_MIN_LENGTH }));
        return false;
      }

      const msg = props.validate(val);

      if (msg) {
        alert(t(msg));
        return false;
      }

      alert('');
      return true;
    };

    const editorToText = () => {
      emit('update:value', iframeDoc.value.body.innerHTML);
    };

    const bold = () => {
      iframeDoc.value.execCommand('bold', false, null);
      input.value.contentWindow.focus();
    };

    const italic = () => {
      iframeDoc.value.execCommand('italic', false, null);
      input.value.contentWindow.focus();
    };

    const underline = () => {
      iframeDoc.value.execCommand('underline', false, null);
      input.value.contentWindow.focus();
    };

    const alignLeft = () => {
      iframeDoc.value.execCommand('justifyLeft', false, null);
      input.value.contentWindow.focus();
    };

    const alignCenter = () => {
      iframeDoc.value.execCommand('justifyCenter', false, null);
      input.value.contentWindow.focus();
    };

    const alignRight = () => {
      iframeDoc.value.execCommand('justifyRight', false, null);
      input.value.contentWindow.focus();
    };

    const alignJustify = () => {
      iframeDoc.value.execCommand('justifyFull', false, null);
      input.value.contentWindow.focus();
    };

    watch(props, (newProps) => {
      iframeDoc.value.body.innerHTML = newProps.value;
    });

    watch(iframeDoc, (doc_) => {
      const doc = doc_;

      doc.designMode = 'on';

      const style = document.createElement('style');
      const css = 'body { font-family: Nunito, Avenir, Helvetica, Arial, sans-serif; font-size:16px; color:#0E100F; margin:0; line-height:22px; padding:0px 10px; background-attachment: local; background-image: linear-gradient(to right, white 0px, transparent 0px), linear-gradient(to left, white 0px, transparent 0px), repeating-linear-gradient(white, white 31.5px, #DBDBDB 31.5px, #DBDBDB 32px, white 32px); line-height: 32px; } p { margin-bottom: 32px; margin-top: 0 } img { max-width: 100% } iframe { width: 100%; height: 56.25vw }';

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      doc.getElementsByTagName('head')[0].appendChild(style);

      doc.body.onblur = () => {
        editorToText();
      };
    });

    return {
      validateInput,
      validateMessage,
      isValid,
      input,
      swap,
      faBold,
      faItalic,
      faUnderline,
      faAlignLeft,
      faAlignRight,
      faAlignCenter,
      faAlignJustify,
      bold,
      italic,
      underline,
      alignLeft,
      alignCenter,
      alignRight,
      alignJustify
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';

.wysiwyg-input {
  display: inline-block;
  position: relative;

  label {
    font-weight: bold;
    padding-left: 2px;
    color: $black;
    font-size: 12px;
    margin-bottom: 3px;
    margin-right: 7px;

    span {
      color: $blue;
    }
  }

  .editor-menu {
    height: 30px;
    box-sizing: border-box;
    border: 1px solid $alto;
    border-left: 0;
    border-right: 0;
    background-color: $white;
    overflow: hidden;
    border-radius: 2px;
    display: inline-block;

    .item {
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      border-left: 1px solid $alto;
      padding-top: 2px;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: top;
      cursor: pointer;
      overflow: hidden;
      text-align: center;
      margin: 0;
      transition: all .2s ease-in-out;

      &:last-child {
        border-right: 1px solid $alto;
      }

      &:hover {
        color: $pink;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .editor {
    border: 1px solid $alto;
    border-radius: 2px;
    padding: 4px;
    font-display: fallback;
    font-family: Nunito, Arial, Tahoma;
    font-size: 14px;
    background-color: $white;
    color: $black;
    width: 100%;
    height: 520px;
    box-sizing: border-box;
    margin-bottom: -5px;

    &::placeholder {
      color: $alto;
    }

    &:active, &:focus {
      outline: none;
    }
  }

  textarea {
    display: none;
  }

  .red {
    color: $pink;
    font-size: 12px;
  }
}
</style>
