<template>
  <div class="modal" tabindex="-1" :id="`modal-${modalId}`" ref="modalRef">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" v-if="showHeader">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <slot name="body" />
        </div>

        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'bootstrap';

export default {
  name: 'Modal',
  components: {},
  props: {
    showHeader: Boolean,
    title: String,
    active: Boolean
  },
  setup(props, { emit }) {
    const modalId = uuidv4();
    const modalRef = ref(null);
    const modalActive = computed(() => props.active);
    const modalObj = ref(null);

    watch(modalActive, (val) => {
      if (val) {
        modalObj.value.show();
      } else {
        modalObj.value.hide();
      }
    });

    onMounted(() => {
      modalObj.value = new Modal(modalRef.value);

      modalRef.value.addEventListener('hidden.bs.modal', () => {
        emit('update:active', false);
      });
    });

    return {
      modalId,
      modalRef
    };
  }
};
</script>
