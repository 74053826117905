<template>
  <div id="story-piece-editor">
    <div class="title">
      <font-awesome-icon :icon="faPizzaSlice" class="icon" />
      <h1>{{ t('STORY_PIECE_EDITOR') }}</h1>
    </div>

    <div class="editor">
      <text-input
        :placeholder="t('TITLE')"
        v-model:value="title"
        required
        :label="t('ENTER_STORY_PIECE_TITLE')"
        ref="titleInput"
        @blur="autoSlug()"
      />

      <text-input
        :placeholder="t('SLUG')"
        v-model:value="slug"
        required
        :label="t('ENTER_SLUG')"
        ref="slugInput"
      />

      <wysiwyg-input
        rows="4"
        :placeholder="t('CONTENT')"
        v-model:value="content"
        :label="t('ENTER_CONTENT')"
        ref="contentInput"
        required
      />

      <autocomplete-input
        v-model:value="story"
        :items="availableStories"
        :placeholder="t('STORY')"
        :label="t('SELECT_STORY')"
        required
        @type="loadStories"
        :bounce="500"
        ref="storyInput"
      />

      <text-input
        :placeholder="t('ORDER')"
        v-model:value="order"
        :label="t('ENTER_ORDER')"
        :format="parseOrder"
        ref="orderInput"
      />

      <file-input
        v-model:value="imageFile"
        :placeholder="t('IMAGE')"
        :label="t('SELECT_IMAGE')"
        ref="imageFileInput"
        v-show="!image"
      />

      <div class="img-holder" v-if="!!image">
        <img :src="image.url" :alt="title" />
        <div class="remove" @click="removeImage()">
          <font-awesome-icon :icon="faTrashAlt" class="icon" />
        </div>
      </div>

      <toggle-input
        :disabled="!canPublish"
        v-model:value="published"
        :label="t('PUBLISH')"
      />
    </div>

    <primary-button :text="t(storyPieceId ? 'UPDATE' : 'ADD')" @click="submit()" />
    <primary-button :text="t('CANCEL')" @click="cancel()" />
  </div>
</template>

<script>
import { onMounted, ref, watchEffect, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPizzaSlice, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import slugify from 'slugify';

import PATHS from '@/consts/paths';
import PERMISSIONS from '@/consts/permissionCodes';

import TextInput from '@/components/TextInput';
import WysiwygInput from '@/components/WysiwygInput';
import ToggleInput from '@/components/ToggleInput';
import FileInput from '@/components/FileInput';
import AutocompleteInput from '@/components/AutocompleteInput';

import PrimaryButton from '@/components/PrimaryButton';

import { normalizeText, getBase64 } from '@/helpers';
import { hasPermissions } from '@/helpers/permission';

import StoryPieceEditorService from './StoryPieceEditorService';

export default {
  title: 'StoryPieceEditor',
  components: {
    FontAwesomeIcon,
    TextInput,
    WysiwygInput,
    ToggleInput,
    FileInput,
    AutocompleteInput,
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const storyPieceId = ref(null);

    const title = ref('');
    const content = ref('');
    const slug = ref('');
    const published = ref(false);
    const imageFile = ref(null);
    const base64Image = ref(null);
    const image = ref(null);

    const story = ref(null);
    const availableStories = ref([]);
    const order = ref(0);

    const titleInput = ref(null);
    const slugInput = ref(null);
    const piecesInput = ref(null);
    const storyInput = ref(null);

    const canPublish = computed(() => hasPermissions([PERMISSIONS.ADMIN_MANAGE_STORY]));

    const autoSlug = () => {
      if (!slug.value) {
        slug.value = slugify(normalizeText(title.value));
      }
    };

    const submit = async () => {
      const inputs = [
        titleInput.value,
        slugInput.value,
        storyInput.value
      ];

      let valid = true;

      inputs.forEach((input) => {
        input.validateInput();
        valid = valid && input.isValid;
      });

      if (valid) {
        const data = {
          title: title.value,
          slug: slug.value,
          content: content.value,
          story: story.value,
          published: published.value,
          order: order.value
        };

        if (image.value?.id) {
          data.image = image.value;
        } else if (imageFile.value) {
          data.image = {
            data: base64Image.value,
            name: imageFile.value.name
          };
        }

        try {
          if (storyPieceId.value) {
            await StoryPieceEditorService.updateStoryPiece(storyPieceId.value, data);
            store.dispatch('flash/info', 'STORY_PIECE_SUCCESSFULLY_UPDATED');
          } else {
            await StoryPieceEditorService.createStoryPiece(data);
            store.dispatch('flash/info', 'STORY_PIECE_SUCCESSFULLY_CREATED');
          }

          router.push(PATHS.STORY_PIECES);
        } catch (e) {
          store.dispatch('flash/error', e.message);
        }
      }
    };

    const cancel = () => {
      router.push(PATHS.STORY_PIECES);
    };

    const removeImage = () => {
      image.value = null;
      imageFile.value = null;
    };

    const loadStories = async (term) => {
      const { items: stories } = await StoryPieceEditorService.getStories(term);

      availableStories.value = stories.map((s) => ({
        value: s.id,
        text: s.title
      }));
    };

    const parseOrder = (val) => {
      const i = parseInt(val, 10);

      return (Number.isNaN(i) ? '' : i);
    };

    watchEffect(async () => {
      if (!imageFile.value) return;
      base64Image.value = await getBase64(imageFile.value);
      image.value = { url: base64Image.value };
    });

    onMounted(async () => {
      const { params: { id } } = route;

      if (id) {
        try {
          const storyPiece = await StoryPieceEditorService.getStoryPiece(id);
          title.value = storyPiece.title;
          slug.value = storyPiece.slug;
          content.value = storyPiece.content;
          image.value = storyPiece.image;
          published.value = storyPiece.published;
          order.value = storyPiece.order;

          storyPieceId.value = id;

          if (storyPiece.story) {
            story.value = storyPiece.story.id;
            availableStories.value = [{
              value: storyPiece.story.id,
              text: storyPiece.story.title
            }];
          }
        } catch(e) {
          store.dispatch('flash/error', e.message);
          router.push(PATHS.STORY_PIECES);
        }
      }
    });

    return {
      t,
      faPizzaSlice,
      title,
      content,
      slug,
      storyPieceId,
      published,
      imageFile,
      autoSlug,
      submit,
      cancel,
      titleInput,
      slugInput,
      piecesInput,
      image,
      faTrashAlt,
      removeImage,
      canPublish,
      loadStories,
      story,
      availableStories,
      storyInput,
      order,
      parseOrder
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

#story-piece-editor {
  .primary-button {
    margin-right: 15px;
  }
}
</style>
