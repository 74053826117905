import ERROR_CODES from '@/consts/errorCodes';

export default class UserNotFound extends Error {
  static code = ERROR_CODES.AUTH_USER_NOT_FOUND;

  constructor(message) {
    super(message || 'User not found.');
    this.code = ERROR_CODES.AUTH_USER_NOT_FOUND;
  }
}
