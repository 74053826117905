import { FLASH_TYPES as TYPES } from '@/consts';

import actions from './actions';
import mutations from './mutations';

const state = {
  message: '',
  isActive: false,
  type: TYPES.INFO
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
