export { default as DEFAULT_PROFILE_PICTURE } from '@/assets/imgs/default-avatar.jpg';

export const PASSWORD_MIN_LENGTH = 6;
export const FLASH_TIMEOUT = 5000;

export const FLASH_TYPES = {
  INFO: 'info',
  ERROR: 'error'
};

export const ORDER_STATUSES = {
  PENDING: 'PENDING',
  SHIPPED: 'SHIPPED',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
};

export default {};
