import HttpMethod from '@/consts/httpMethod';
import { processRequest, processErrorResponse, processSuccessResponse } from '@/helpers';

export default {
  async getProducts(params) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: '/v1/products',
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, { params });
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      console.log('????????????????', e);
      processErrorResponse(e.response);
      return false;
    }
  },

  async removeProduct(id) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/products/${id}`,
      method: HttpMethod.DELETE
    };

    try {
      await processRequest(ep);
      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
