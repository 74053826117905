import HttpMethod from '@/consts/httpMethod';
import { processRequest, processErrorResponse, processSuccessResponse } from '@/helpers';

export default {
  async getStories(params) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: '/v1/stories',
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, { params });
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async removeStory(id) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/stories/${id}`,
      method: HttpMethod.DELETE
    };

    try {
      await processRequest(ep);
      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async toggleStory(id, published) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/stories/${id}/published`,
      method: HttpMethod.PUT
    };

    try {
      await processRequest(ep, { data: { published } });
      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
