<template>
  <div id="login">
    <div class="panel">
      <logo />
      <br />
      <form @submit="submit">
        <text-input
          :placeholder="t('USERNAME')"
          v-model:value="username"
          required
          :label="t('ENTER_YOUR_USERNAME')"
          ref="usernameInput"
        />

        <text-input
          :placeholder="t('PASSWORD')"
          type="password"
          v-model:value="password"
          required
          :label="t('ENTER_YOUR_PASSWORD')"
          ref="passwordInput"
        />

        <primary-button :text="t('LOG_IN')" type="submit" />
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import PATHS from '@/consts/paths';

import Logo from '@/components/Logo';
import TextInput from '@/components/TextInput';
import PrimaryButton from '@/components/PrimaryButton';

export default {
  name: 'Login',
  components: {
    Logo,
    TextInput,
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const username = ref('');
    const usernameInput = ref(null);

    const password = ref('');
    const passwordInput = ref(null);

    const login = async () => {
      const inputs = [
        usernameInput.value,
        passwordInput.value
      ];

      let valid = true;

      inputs.forEach((input) => {
        input.validateInput();
        valid = valid && input.isValid;
      });

      if (!valid) return;

      try {
        await store.dispatch('user/login', { username: username.value, password: password.value });
        router.push(PATHS.HOME);
      } catch(e) {
        store.dispatch('flash/error', 'LOGIN_ERROR');
      }
    };

    const submit = (e) => {
      e.preventDefault();
      login();
    };

    return {
      t,
      username,
      usernameInput,
      password,
      passwordInput,
      submit
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

#login {
  padding-top: 80px;

  .panel {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: 15px;

    .logo {
      width: 90px;
      margin-bottom: 30px;
      color: $blue;
    }

    .text-input {
      display: block;
      margin: 0 auto;
      max-width: 320px;
      text-align: left;
    }

    .primary-button {
      display: block;
      margin: 0 auto;
      width: 320px;
      margin-top: 10px;
      text-transform: uppercase;
    }
  }
}
</style>
