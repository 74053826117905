<template>
  <div id="user-editor">
    <div class="title">
      <font-awesome-icon :icon="faPizzaSlice" class="icon" />
      <h1>{{ t('USER_EDITOR') }}</h1>
    </div>

    <div class="editor">
      <text-input
        :placeholder="t('USERNAME')"
        v-model:value="username"
        required
        :label="t('ENTER_USERNAME')"
        ref="usernameInput"
        :disabled="sameUser"
      />

      <text-input
        :placeholder="t('PASSWORD')"
        v-model:value="password"
        type="password"
        :label="t('ENTER_PASSWORD_TO_CHANGE')"
        ref="passwordInput"
        :disabled="sameUser"
      />

      <text-input
        :placeholder="t('FIRST_NAME')"
        v-model:value="firstName"
        required
        :label="t('ENTER_FIRST_NAME')"
        ref="firstNameInput"
        :disabled="sameUser"
      />

      <text-input
        :placeholder="t('LAST_NAME')"
        v-model:value="lastName"
        :label="t('ENTER_LAST_NAME')"
        ref="lastNameInput"
        :disabled="sameUser"
      />

      <autocomplete-input
        v-model:value="groups"
        :items="availableGroups"
        :placeholder="t('GROUPS')"
        :label="t('SELECT_GROUPS')"
        multiple
        ref="groupsInput"
        :disabled="sameUser"
      />

      <toggle-input
        v-model:value="superuser"
        :label="t('SUPERUSER')"
        :disabled="sameUser"
      />
      <br />

      <toggle-input
        v-model:value="active"
        :label="t('ACTIVE')"
        :disabled="sameUser"
      />
    </div>

    <primary-button :text="t(userId ? 'UPDATE' : 'ADD')" @click="submit()" :disabled="sameUser" />
    <primary-button :text="t('CANCEL')" @click="cancel()" />
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPizzaSlice, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';

import PATHS from '@/consts/paths';

import TextInput from '@/components/TextInput';
import AutocompleteInput from '@/components/AutocompleteInput';
import ToggleInput from '@/components/ToggleInput';

import PrimaryButton from '@/components/PrimaryButton';

import UserEditorService from './UserEditorService';

export default {
  name: 'UserEditor',
  components: {
    FontAwesomeIcon,
    TextInput,
    AutocompleteInput,
    ToggleInput,
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const userProfile = computed(() => store.state.user.profile);
    const sameUser = ref(false);

    const userId = ref(null);

    const groups = ref([]);
    const availableGroups = ref([]);

    const firstName = ref('');
    const lastName = ref('');
    const username = ref('');
    const password = ref('');
    const active = ref(false);
    const superuser = ref(false);

    const firstNameInput = ref(null);
    const usernameInput = ref(null);

    const submit = async () => {
      const inputs = [
        firstNameInput.value,
        usernameInput.value
      ];

      let valid = true;

      inputs.forEach((input) => {
        input.validateInput();
        valid = valid && input.isValid;
      });

      if (valid) {
        const data = {
          firstName: firstName.value,
          lastName: lastName.value,
          username: username.value,
          password: password.value,
          active: active.value,
          superuser: superuser.value,
          groups: groups.value,
        };

        try {
          if (userId.value) {
            await UserEditorService.updateUser(userId.value, data);
            store.dispatch('flash/info', 'USER_SUCCESSFULLY_UPDATED');
          } else {
            await UserEditorService.createUser(data);
            store.dispatch('flash/info', 'USER_SUCCESSFULLY_CREATED');
          }

          router.push(PATHS.USERS);
        } catch (e) {
          store.dispatch('flash/error', e.message);
        }
      }
    };

    const cancel = () => {
      router.push(PATHS.USERS);
    };

    onMounted(async () => {
      const { params: { id } } = route;

      if (id) {
        try {
          const user = await UserEditorService.getUser(id);
          firstName.value = user.firstName;
          lastName.value = user.lastName;
          username.value = user.username;
          groups.value = user.groups;
          active.value = user.active;
          superuser.value = user.superuser;
          userId.value = id;

          if (user.username === userProfile.value.username) {
            sameUser.value = true;
          }
        } catch(e) {
          store.dispatch('flash/error', e.message);
          router.push(PATHS.USERS);
        }
      }

      const { items } = await UserEditorService.getGroups();
      availableGroups.value = items.map((item) => ({
        text: item.name,
        value: item.id
      }));
    });

    return {
      t,
      faPizzaSlice,
      availableGroups,
      groups,
      userId,
      submit,
      cancel,
      firstName,
      lastName,
      username,
      password,
      active,
      superuser,
      firstNameInput,
      usernameInput,
      faTrashAlt,
      sameUser
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

#user-editor {
  .primary-button {
    margin-right: 15px;
  }
}
</style>
