<template>
  <div id="home">
    <div class="container panel">
      <app-header />
      <div class="main">
        <router-view />
      </div>
      <app-footer />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

import AppHeader from '@/components/Header';
import AppFooter from '@/components/Footer';

export default {
  name: 'Home',
  components: {
    AppHeader,
    AppFooter
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import '~@/assets/scss/dimens';

#home {
  background-color: $almostWhite;
  min-height: calc(100vh - #{$headerHeight});

  .panel {
    background-color: $white;
    border: 1px solid $alto;
    border-top: 0;
    padding: 50px 30px;

    .main {
      margin-bottom: 30px;
    }
  }
}
</style>
