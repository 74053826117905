import actions from './actions';
import mutations from './mutations';

const state = () => ({
  screenSize: 1024,
  isMobile: false,
  isTablet: false,
  isDesktop: true
});

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
