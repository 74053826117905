export default {
  HOME: '/',
  PANEL: '/panel',
  LOGIN: '/login',
  CHANGE_PASSWORD: '/change-password',
  USERS: '/users',
  USER_EDITOR: '/user-editor/:id?',
  STORIES: '/stories',
  STORY_EDITOR: '/story-editor/:id?',
  STORY_PIECES: '/story-pieces',
  STORY_PIECE_EDITOR: '/story-piece-editor/:id?',

  PRODUCTS: '/products',
  PRODUCT_EDITOR: '/product-editor/:id?',

  ORDERS: '/orders',
};
