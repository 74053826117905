<template>
  <div class="file-input input">
    <div class="input-wrapper">
      <text-input
        :disabled="disabled"
        :label="label"
        :placeholder="placeholder"
        :required="required"
        :value="fileName"
        icon-position="right"
        @focus="toggleBrowseFile()"
        ref="textInputRef"
      >
        <template v-slot:icon>
          <font-awesome-icon class="icon" :icon="faCaretDown" />
        </template>
      </text-input>

      <input type="file" cloak ref="fileInputRef" @change="select" />
    </div>

    <small class="red" v-if="!!validateMessage">{{ validateMessage }}</small>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from 'vue-i18n';

import TextInput from '@/components/TextInput';

export default {
  name: 'FileInput',
  props: {
    placeholder: String,
    label: String,
    value: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    TextInput,
    FontAwesomeIcon
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const fileInputRef = ref(null);
    const textInputRef = ref(null);
    const validateMessage = ref('');
    const isValid = ref(false);
    const browsing = ref(false);

    const wrappedValue = computed({
      get() { return props.value; },
      set(val) {
        emit('update:value', val);
      }
    });

    const fileName = computed(() => wrappedValue.value?.name);

    const toggleBrowseFile = () => {
      if (browsing.value) return;

      if (!fileInputRef.value) return;
      browsing.value = true;
      fileInputRef.value.click();
    };

    const select = (e) => {
      const file = e.target.files[0];

      if (file) {
        wrappedValue.value = file;
      }

      textInputRef.value.blur();
      browsing.value = false;
    };

    const alert = (msg) => {
      validateMessage.value = msg;
    };

    const validateInput = () => {
      const val = wrappedValue.value;

      if (props.required && !val) {
        alert(t('REQUIRED_FIELD'));
        return false;
      }

      const msg = props.validate(wrappedValue.value);

      if (msg) {
        alert(t(msg));
        return false;
      }

      alert('');
      return true;
    };

    return {
      fileName,
      toggleBrowseFile,
      select,
      faTimes,
      fileInputRef,
      validateInput,
      validateMessage,
      isValid,
      faCaretDown,
      textInputRef
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';

.file-input {
  position: relative;

  small {
    &.red {
      color: $pink;
    }
  }
}
</style>
