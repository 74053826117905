import { createRouter, createWebHistory } from 'vue-router';

import { scrollToTop } from '@/helpers';
import { hasPermissions } from '@/helpers/permission';
import store from '@/store';

import PATHS from './consts/paths';
import PERMISSIONS from './consts/permissionCodes';

import Home from './views/Home';
import Login from './views/Login';
import Panel from './views/Panel';
import ChangePassword from './views/ChangePassword';

import Users from './views/Users';
import UserEditor from './views/UserEditor';

import Stories from './views/Stories';
import StoryEditor from './views/StoryEditor';

import StoryPieces from './views/StoryPieces';
import StoryPieceEditor from './views/StoryPieceEditor';

import Products from './views/Products';
import ProductEditor from './views/ProductEditor';

import Orders from './views/Orders';

const routes = [
  { path: '/:catchAll(.*)', redirect: PATHS.HOME },
  {
    path: PATHS.LOGIN,
    component: Login,
    name: 'login',
    meta: {
      requiresUnauth: true,
      headless: true,
      footless: true
    },
  },
  {
    path: PATHS.HOME,
    component: Home,
    name: 'home',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: PATHS.HOME,
        component: Panel,
        name: 'panel'
      },
      {
        path: PATHS.CHANGE_PASSWORD,
        component: ChangePassword,
        name: 'change-password'
      },
      {
        path: PATHS.USERS,
        component: Users,
        name: 'users-list',
        meta: {
          permissions: [PERMISSIONS.ADMIN_MANAGE_USER]
        }
      },
      {
        path: PATHS.USER_EDITOR,
        component: UserEditor,
        name: 'user-editor',
        meta: {
          permissions: [PERMISSIONS.ADMIN_MANAGE_USER]
        }
      },
      {
        path: PATHS.STORIES,
        component: Stories,
        name: 'stories-list',
        meta: {
          permissions: [PERMISSIONS.ADMIN_MANAGE_STORY]
        }
      },
      {
        path: PATHS.STORY_EDITOR,
        component: StoryEditor,
        name: 'story-editor',
        meta: {
          permissions: [PERMISSIONS.ADMIN_MANAGE_STORY]
        }
      },
      {
        path: PATHS.STORY_PIECES,
        component: StoryPieces,
        name: 'story-pieces-list',
        meta: {
          permissions: [PERMISSIONS.ADMIN_MANAGE_STORY]
        }
      },
      {
        path: PATHS.STORY_PIECE_EDITOR,
        component: StoryPieceEditor,
        name: 'story-piece-editor',
        meta: {
          permissions: [PERMISSIONS.ADMIN_MANAGE_STORY]
        }
      },

      {
        path: PATHS.PRODUCTS,
        component: Products,
        name: 'products-list',
        meta: {
          permissions: [PERMISSIONS.ADMIN_MANAGE_PRODUCT]
        }
      },
      {
        path: PATHS.PRODUCT_EDITOR,
        component: ProductEditor,
        name: 'product-editor',
        meta: {
          permissions: [PERMISSIONS.ADMIN_MANAGE_PRODUCT]
        }
      },

      {
        path: PATHS.ORDERS,
        component: Orders,
        name: 'orders-list',
        meta: {
          permissions: [PERMISSIONS.ADMIN_MANAGE_ORDER]
        }
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name !== from.name) {
      scrollToTop();
    }

    return savedPosition;
  }
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth)
    && !(
      store.getters['user/isLoggedIn']
      && hasPermissions([PERMISSIONS.ADMIN_ACCESS])
    )
  ) {
    store.dispatch('user/logout');
    next({ path: PATHS.LOGIN });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresUnauth)
  && store.getters['user/isLoggedIn']) {
    next({ path: PATHS.HOME });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const matched = to.matched.filter((record) => !!record.meta.permissions?.length);

  if (matched.length && !matched.every((record) => hasPermissions(record.meta.permissions))) {
    next({ path: PATHS.HOME });
  } else {
    next();
  }
});

export default router;
