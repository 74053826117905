<template>
  <div id="panel">
    <section v-for="section in sections" :key="section.title">
      <h2>{{ t(section.title) }}</h2>
      <div class="row items">
        <div
          v-for="item in section.items"
          :key="item.text"
          class="col col-xs-12 col-md-4"
        >
          <div class="item">
            <router-link :to="item.path">
              <font-awesome-icon :icon="item.icon" class="icon" />
              <div class="text">{{ t(item.text) }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUsers, faTypewriter, faScroll, faBox, faInboxFull } from '@fortawesome/pro-duotone-svg-icons';

import PATHS from '@/consts/paths';
import PERMISSIONS from '@/consts/permissionCodes';
import { hasPermissions } from '@/helpers/permission';

const adminItems = [
  {
    icon: faUsers,
    text: 'USERS',
    path: PATHS.USERS,
    permissions: [PERMISSIONS.ADMIN_MANAGE_USER]
  }
];

const contentItems = [
  {
    icon: faScroll,
    text: 'STORIES',
    path: PATHS.STORIES,
    permissions: [PERMISSIONS.ADMIN_MANAGE_STORY]
  },

  {
    icon: faTypewriter,
    text: 'STORY_PIECES',
    path: PATHS.STORY_PIECES,
    permissions: [PERMISSIONS.ADMIN_MANAGE_STORY]
  },
  {
    icon: faBox,
    text: 'PRODUCTS',
    path: PATHS.PRODUCTS,
    permissions: [PERMISSIONS.ADMIN_MANAGE_PRODUCT]
  }
];

const orderItems = [
  {
    icon: faInboxFull,
    text: 'ORDERS',
    path: PATHS.ORDERS,
    permissions: [PERMISSIONS.ADMIN_MANAGE_ORDER]
  }
];

export default {
  name: 'Home',
  components: {
    FontAwesomeIcon
  },
  setup() {
    const { t } = useI18n();

    const sections = computed(() => {
      const result = [
        {
          title: 'ADMINISTRATION',
          items: adminItems.filter((item) => hasPermissions(item.permissions)),
        },
        {
          title: 'CONTENT_MANAGEMENT',
          items: contentItems.filter((item) => hasPermissions(item.permissions))
        },
        {
          title: 'ORDER_MANAGEMENT',
          items: orderItems.filter((item) => hasPermissions(item.permissions))
        }
      ];

      return result.filter((sect) => sect.items.length);
    });

    return {
      t,
      sections
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
@import '~@/assets/scss/dimens';

#panel {
  section {
    margin-bottom: 50px;

    h2 {
      font-size: 18px;
      font-weight: bold;
      text-transform: capitalize;
    }

    .items {
      margin-top: 30px;

      .item {
        cursor: pointer;

        a {
          color: inherit;
        }

        .icon {
          width: 40px;
          height: 40px;
          margin-right: 15px;
          vertical-align: middle;
          color: $blue;
          transition: all .2s ease-in-out;
        }

        .text {
          display: inline-block;
          vertical-align: middle;
          padding-top: 5px;
          font-size: 14px;
          transition: all .2s ease-in-out;
        }

        &:hover {
          .icon {
            color:$pink;
          }

          .text {
            font-weight: bold;
            color:$pink;
          }
        }
      }
    }
  }
}
</style>
