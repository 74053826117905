<template>
  <div
    class="toggle-input input"
    :class="{
      disabled
    }"
  >
    <div class="toggle-wrapper" :class="{ active: value }" @click="toggle()">
      <div class="toggle"></div>
    </div>
    <label v-if="!!label">{{ label }}</label>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'ToggleInput',
  props: {
    label: String,
    value: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  setup(props, { emit }) {
    const wrappedValue = computed({
      get() { return props.value; },
      set(val) {
        emit('update:value', val);
      }
    });

    const toggle = () => {
      wrappedValue.value = !wrappedValue.value;
    };

    return {
      toggle
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';

.toggle-input {
  display: inline-block;
  position: relative;

  label {
    font-weight: bold;
    padding-left: 2px;
    color: $black;
    font-size: 12px;
    margin-bottom: 3px;
    margin-right: 7px;
    margin-left: 10px;
    display: inline-block;
  }

  .toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 22px;
    border-radius: 22px;
    background-color: $alto;
    position: relative;
    cursor: pointer;
    transition: all .2s ease-in-out;

    .toggle {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: $white;
      transition: all .2s ease-in-out;
    }

    &.active {
      background-color: $blue;

      .toggle {
        left: calc(100% - 20px);
      }
    }
  }

  &.disabled {
    pointer-events: none;
    color: $grey;

    label {
      color: $grey;
    }

    .toggle-wrapper {
      background-color: $alto !important;
    }
  }
}
</style>
