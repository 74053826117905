<template>
  <div id="product-editor">
    <div class="name">
      <font-awesome-icon :icon="faBox" class="icon" />
      <h1>{{ t('PRODUCT_EDITOR') }}</h1>
    </div>

    <div class="editor">
      <text-input
        :placeholder="t('NAME')"
        v-model:value="name"
        required
        :label="t('ENTER_PRODUCT_NAME')"
        ref="nameInput"
        @blur="autoSlug()"
      />

      <text-input
        :placeholder="t('SLUG')"
        v-model:value="slug"
        required
        :label="t('ENTER_SLUG')"
        ref="slugInput"
      />

      <textarea-input
        rows="4"
        :placeholder="t('DESCRIPTION')"
        v-model:value="description"
        :label="t('ENTER_DESCRIPTION')"
        ref="descriptionInput"
      />

      <file-input
        v-model:value="imageFile"
        :placeholder="t('IMAGE')"
        :label="t('SELECT_IMAGE')"
        ref="imageFileInput"
        v-show="!image"
      />

      <div class="img-holder" v-if="!!image">
        <img :src="image.url" :alt="name" />
        <div class="remove" @click="removeImage()">
          <font-awesome-icon :icon="faTrashAlt" class="icon" />
        </div>
      </div>

      <text-input
        v-model:value="stock"
        :label="t('STOCK')"
        type="integer"
        required
        ref="stockInput"
      />

      <text-input
        v-model:value="formatedPrice"
        :label="t('PRICE')"
        type="money"
        required
        ref="priceInput"
      />

      <wysiwyg-input
        rows="4"
        :placeholder="t('CONTENT')"
        v-model:value="content"
        :label="t('ENTER_CONTENT')"
        ref="contentInput"
        required
      />

      <file-input
        v-model:value="galleryImageFile"
        :placeholder="t('GALLERY_IMAGE')"
        :label="t('SELECT_IMAGE')"
        ref="galleryImageFileInput"
      />

      <div class="img-holder-list">
        <div class="img-holder" v-for="(img, i) in gallery" :key="img.id">
          <img :src="img.url || img.data" :alt="name" />
          <div class="remove" @click="removeGalleryImage(i)">
            <font-awesome-icon :icon="faTrashAlt" class="icon" />
          </div>
        </div>
      </div>
    </div>

    <primary-button :text="t(productId ? 'UPDATE' : 'ADD')" @click="submit()" />
    <primary-button :text="t('CANCEL')" @click="cancel()" />
  </div>
</template>

<script>
import { onMounted, ref, watchEffect, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBox, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import slugify from 'slugify';

import PATHS from '@/consts/paths';

import TextInput from '@/components/TextInput';
import TextareaInput from '@/components/TextareaInput';
import FileInput from '@/components/FileInput';
import WysiwygInput from '@/components/WysiwygInput';

import PrimaryButton from '@/components/PrimaryButton';

import { normalizeText, getBase64, addCommas, removeCommas } from '@/helpers';

import ProductEditorService from './ProductEditorService';

export default {
  title: 'ProductEditor',
  components: {
    FontAwesomeIcon,
    TextInput,
    TextareaInput,
    FileInput,
    PrimaryButton,
    WysiwygInput
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const productId = ref(null);

    const name = ref('');
    const description = ref('');
    const slug = ref('');
    const stock = ref(0);
    const imageFile = ref(null);
    const base64Image = ref(null);
    const image = ref(null);

    const galleryImageFile = ref(null);

    const content = ref('');
    const gallery = ref([]);

    const formatedPrice = ref(0);
    const price = computed(() => removeCommas(formatedPrice.value));

    const nameInput = ref(null);
    const slugInput = ref(null);
    const stockInput = ref(null);
    const priceInput = ref(null);

    const autoSlug = () => {
      if (!slug.value) {
        slug.value = slugify(normalizeText(name.value));
      }
    };

    const submit = async () => {
      const inputs = [
        nameInput.value,
        slugInput.value,
        stockInput.value,
        priceInput.value
      ];

      let valid = true;

      inputs.forEach((input) => {
        input.validateInput();
        valid = valid && input.isValid;
      });

      if (valid) {
        const data = {
          name: name.value,
          slug: slug.value,
          description: description.value,
          stock: stock.value,
          price: price.value,
          gallery: gallery.value,
          content: content.value
        };

        if (image.value?.id) {
          data.image = image.value;
        } else if (imageFile.value) {
          data.image = {
            data: base64Image.value,
            name: imageFile.value.name
          };
        }

        try {
          if (productId.value) {
            await ProductEditorService.updateProduct(productId.value, data);
            store.dispatch('flash/info', 'PRODUCT_SUCCESSFULLY_UPDATED');
          } else {
            await ProductEditorService.createProduct(data);
            store.dispatch('flash/info', 'PRODUCT_SUCCESSFULLY_CREATED');
          }

          router.push(PATHS.PRODUCTS);
        } catch (e) {
          store.dispatch('flash/error', e.message);
        }
      }
    };

    const cancel = () => {
      router.push(PATHS.PRODUCTS);
    };

    const removeImage = () => {
      image.value = null;
      imageFile.value = null;
    };

    const removeGalleryImage = (i) => {
      gallery.value = [
        ...gallery.value.slice(0, i),
        ...gallery.value.slice(i + 1, gallery.value.length)
      ];
    };

    watchEffect(async () => {
      if (!imageFile.value) return;
      base64Image.value = await getBase64(imageFile.value);
      image.value = { url: base64Image.value };
    });

    watchEffect(async () => {
      if (!galleryImageFile.value) return;
      const base64Img = await getBase64(galleryImageFile.value);
      gallery.value = [...gallery.value, { data: base64Img, name: galleryImageFile.value.name }];

      galleryImageFile.value = null;
    });

    onMounted(async () => {
      const { params: { id } } = route;

      if (id) {
        try {
          const product = await ProductEditorService.getProduct(id);
          name.value = product.name;
          slug.value = product.slug;
          description.value = product.description;
          image.value = product.image;
          stock.value = product.stock;
          formatedPrice.value = addCommas(product.price);
          productId.value = id;

          content.value = product.content;
          gallery.value = product.gallery || [];
        } catch(e) {
          store.dispatch('flash/error', e.message);
          router.push(PATHS.PRODUCTS);
        }
      }
    });

    return {
      t,
      faBox,
      name,
      description,
      slug,
      productId,
      stock,
      imageFile,
      autoSlug,
      submit,
      cancel,
      nameInput,
      slugInput,
      image,
      faTrashAlt,
      removeImage,
      formatedPrice,
      priceInput,
      stockInput,

      content,
      gallery,
      galleryImageFile,
      removeGalleryImage
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

#product-editor {
  .primary-button {
    margin-right: 15px;
  }
}
</style>
