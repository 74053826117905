<template>
  <div
    class="textarea-input input"
    :class="{
      disabled
    }"
  >
    <textarea
      v-bind="$attrs"
      v-bind:placeholder="placeholder"
      v-model="wrappedValue"
      v-on="inputListeners"
      ref="input"
    />

    <label v-if="!!label">{{ label }} <span v-if="required && !!label">*</span></label>
    <div v-if="!!validateMessage" class="red">{{ validateMessage }}</div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import validator from 'validator';
import { useI18n } from 'vue-i18n';

import { PASSWORD_MIN_LENGTH } from '@/consts';

export default {
  name: 'TextareaInput',
  props: {
    placeholder: String,
    label: String,
    value: {
      type: [String, Number],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: () => ''
    },
    format: {
      type: Function,
      default: (val) => val
    },
    disabled: Boolean
  },
  setup(props, { emit, listeners, refs }) {
    const { t } = useI18n();

    const validateMessage = ref('');
    const isValid = ref(false);
    const cacheValue = ref(props.value);
    const input = ref(null);

    const wrappedValue = computed({
      get() { return cacheValue.value; },
      set(value) {
        cacheValue.value = value;
      }
    });

    const inputListeners = {
      ...listeners,
      click() {
        input.value.focus();
        emit('click');
      },
      input(event) {
        const val = props.format(event.target.value);
        cacheValue.value = val;
        emit('update:value', val);
      }
    };

    const alert = (msg) => {
      validateMessage.value = msg;
    };

    const validateInput = () => {
      const val = wrappedValue.value;

      if ((props.required) && !val) {
        alert(t('REQUIRED_FIELD'));
        return false;
      }

      if ((props.type === 'email') && !(val && validator.isEmail(val))) {
        alert(t('INVALID_EMAIL'));
        return false;
      }

      if ((props.type === 'password') && (val.length < PASSWORD_MIN_LENGTH)) {
        alert(t('PASSWORD_MUST_BE_AT_LEAST', { min: PASSWORD_MIN_LENGTH }));
        return false;
      }

      const msg = props.validate(val);

      if (msg) {
        alert(t(msg));
        return false;
      }

      alert('');
      return true;
    };

    const blur = () => {
      refs.input.blur();
    };

    watch(props, (newProps) => {
      cacheValue.value = newProps.value;
    });

    watch(wrappedValue, () => {
      isValid.value = validateInput();
    });

    return {
      validateInput,
      validateMessage,
      isValid,
      inputListeners,
      wrappedValue,
      blur,
      input
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';

.textarea-input {
  display: inline-block;
  position: relative;

  label {
    font-weight: bold;
    padding-left: 2px;
    color: $black;
    font-size: 12px;
    margin-bottom: 3px;
    margin-right: 7px;

    span {
      color: $blue;
    }
  }

  textarea {
    border: 1px solid $alto;
    border-radius: 2px;
    padding: 4px;
    font-display: fallback;
    font-family: Nunito, Arial, Tahoma;
    font-size: 14px;
    background-color: $white;
    color: $black;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: -5px;

    &::placeholder {
      color: $alto;
    }

    &:active, &:focus {
      outline: none;
    }
  }

  .red {
    color: $pink;
    font-size: 12px;
  }
}
</style>
