<template>
  <div id="users-list">
    <div class="title">
      <font-awesome-icon :icon="faUsers" class="icon" />
      <h1>{{ t('USERS') }}</h1>

      <router-link :to="applyParams(PATHS.USER_EDITOR, '')">
        <div class="add-btn">
          <cross white />
        </div>
      </router-link>
    </div>

    <div class="list">
      <div class="row">
        <div
          class="col col-xs-12 col-md-6 col-lg-4"
          v-for="(col, i) in itemsGrid"
          :key="i"
        >
          <div
            v-for="item in itemsGrid[i]"
            :key="item.id"
            class="item"
          >
            <div class="box">
              <div class="controls" v-if="userProfile.username !== item.username">
                <router-link :to="applyParams(PATHS.USER_EDITOR, item.id)">
                  <font-awesome-icon :icon="faPenAlt" class="icon" />
                </router-link>
                <font-awesome-icon :icon="faTrashAlt" class="icon" @click="removeUser(item.id)" />
              </div>

              <router-link :to="applyParams(PATHS.USER_EDITOR, item.id)">
                <h3 :class="{ inactive: !item.active }">{{ item.username }}</h3>
              </router-link>
              <small>{{ `${item.firstName || ''} ${item.lastName || ''}` }}</small>
              <p>
                <ul v-if="!!item.groups?.length">
                  <li v-for="group in item.groups" :key="group.name">{{ group.name }}</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUsers, faTrashAlt, faPenAlt, faToggleOn, faToggleOff } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import Cross from '@/components/Cross';

import PATHS from '@/consts/paths';
import { applyParams } from '@/helpers';

import UsersService from './UsersService';

export default {
  name: 'UsersList',
  components: {
    FontAwesomeIcon,
    Cross
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const calculateItemHeightScore = (item) => {
      let h = 0;
      if (item?.firstName) h += item.firstName.length;
      if (item?.lastName) h += item.lastName.length;
      if (item?.username) h += item.username.length;
      if (item?.groups) h += item.groups.length;

      return h;
    };

    const userProfile = computed(() => store.state.user.profile);

    const items = computed(() => store.state.users.items);
    const itemsGrid = computed(() => {
      let n = 3;
      if (store.state.app.isTablet) {
        n = 2;
      } else if (store.state.app.isMobile) {
        n = 1;
      }

      const stacks = Array(n).fill().map(() => []);
      const stackHeights = Array(n).fill(0);

      for (let i = 0; i < items.value.length; i += 1) {
        const minStack = stackHeights.indexOf(Math.min(...stackHeights));
        stacks[minStack].push({ ...items.value[i] });
        stackHeights[minStack] += calculateItemHeightScore(items.value[i]);
      }

      return stacks;
    });

    const removeUser = async (id) => {
      try {
        await UsersService.removeUser(id);
        store.dispatch('users/getUsers');
        store.dispatch('flash/info', 'USER_SUCCESSFULLY_REMOVED');
      } catch(e) {
        store.dispatch('flash/error', e.message);
      }
    };

    onMounted(() => {
      store.dispatch('users/getUsers');
    });

    return {
      t,
      faUsers,
      faPlus,
      itemsGrid,
      PATHS,
      faTrashAlt,
      faPenAlt,
      removeUser,
      applyParams,
      faToggleOn,
      faToggleOff,
      userProfile
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";
</style>
