<template>
  <div
    class="text-input input"
    :class="{
      disabled,
      'has-icon': hasIcon,
      'icon-right': (iconPosition === 'right')
    }"
  >
    <input
      v-bind="$attrs"
      v-bind:type="type"
      v-bind:placeholder="placeholder"
      v-model="wrappedValue"
      v-on="inputListeners"
      ref="input"
    />

    <slot v-if="hasIcon" name="icon" />

    <label v-if="!!label">{{ label }} <span v-if="required && !!label">*</span></label>
    <div v-if="!!validateMessage" class="red">{{ validateMessage }}</div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import validator from 'validator';
import { useI18n } from 'vue-i18n';

import { PASSWORD_MIN_LENGTH } from '@/consts';
import { addCommas } from '@/helpers';

export default {
  name: 'TextInput',
  props: {
    placeholder: String,
    label: String,
    iconPosition: {
      type: String,
      default: 'left'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: () => ''
    },
    format: {
      type: Function,
      default: (val) => val
    },
    disabled: Boolean
  },
  setup(props, { emit, slots, listeners }) {
    const { t } = useI18n();

    const validateMessage = ref('');
    const isValid = ref(false);
    const cacheValue = ref(props.value);
    const input = ref(null);

    const wrappedValue = computed({
      get() { return cacheValue.value; },
      set(value) {
        cacheValue.value = value;
      }
    });

    const hasIcon = computed(() => !!slots.icon);

    const formatInput = (val_) => {
      let val = val_;

      switch(props.type) {
        case 'integer': {
          if ((val === '-') || (val === '')) {
            // do nothing
          } else {
            val = parseInt(val, 10);

            if (Number.isNaN(val)) {
              val = 0;
            }
          }

          break;
        }

        case 'money': {
          val = addCommas(val);
          break;
        }

        default:
          break;
      }

      return val;
    };

    const inputListeners = {
      ...listeners,
      click() {
        input.value.focus();
        emit('click');
      },
      input(event) {
        const val = props.format(formatInput(event.target.value));
        cacheValue.value = val;
        emit('update:value', val);
      }
    };

    const alert = (msg) => {
      validateMessage.value = msg;
    };

    const validateInput = () => {
      const val = wrappedValue.value;

      if ((props.required) && !val) {
        alert(t('REQUIRED_FIELD'));
        return false;
      }

      if ((props.type === 'email') && !(val && validator.isEmail(val))) {
        alert(t('INVALID_EMAIL'));
        return false;
      }

      if ((props.type === 'password') && (val.length < PASSWORD_MIN_LENGTH)) {
        alert(t('PASSWORD_MUST_BE_AT_LEAST', { min: PASSWORD_MIN_LENGTH }));
        return false;
      }

      const msg = props.validate(val);

      if (msg) {
        alert(t(msg));
        return false;
      }

      alert('');
      return true;
    };

    const blur = () => {
      input.value.blur();
    };

    watch(props, (newProps) => {
      cacheValue.value = newProps.value;
    });

    watch(wrappedValue, () => {
      isValid.value = validateInput();
    });

    return {
      validateInput,
      validateMessage,
      isValid,
      hasIcon,
      inputListeners,
      wrappedValue,
      blur,
      input
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';

.text-input {
  display: inline-block;
  position: relative;

  .icon {
    color: $black;
    position: absolute;
    top: 10px;
    left: 15px;
  }

  label {
    font-weight: bold;
    padding-left: 2px;
    color: $black;
    font-size: 12px;
    margin-bottom: 3px;
    margin-right: 7px;

    span {
      color: $blue;
    }
  }

  input {
    border: 0;
    border-bottom: 1px solid $alto;
    border-radius: 0;
    padding: 4px;
    font-display: fallback;
    font-family: Nunito, Arial, Tahoma;
    font-size: 14px;
    background-color: $white;
    color: $black;
    width: 100%;
    height: 36px;
    box-sizing: border-box;

    &::placeholder {
      color: $alto;
    }

    &:active, &:focus {
      outline: none;
    }
  }

  .red {
    color: $pink;
    font-size: 12px;
  }

  &.has-icon {
    input {
      padding-left: 40px;
    }

    &.icon-right {
      input {
        padding-left: 4px;
        padding-right: 40px;
      }

      .icon {
        left: auto;
        right: 15px;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    color: $grey;

    input, label {
      color: $grey;
    }
  }
}
</style>
