import HttpMethod from '@/consts/httpMethod';
import { processRequest, processErrorResponse, processSuccessResponse } from '@/helpers';

export default {
  async getStoryPieces(params) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: '/v1/story-pieces',
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, { params });
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async removeStoryPiece(id) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/story-pieces/${id}`,
      method: HttpMethod.DELETE
    };

    try {
      await processRequest(ep);
      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async toggleStoryPiece(id, published) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/story-pieces/${id}/published`,
      method: HttpMethod.PUT
    };

    try {
      await processRequest(ep, { data: { published } });
      return true;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  }
};
