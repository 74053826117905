import productsService from '@/views/Products/ProductsService';

export default {
  applyFilters({ commit }, filters) {
    commit('applyFilters', filters);
  },

  async getProducts({ commit, state }, { append = true }) {
    if (state.fetching) return;
    commit('setFetching', { val: true });

    try {
      const { items: items_, total } = await productsService.getProducts(state.filters);
      let items = [];
      if ((state.filters.page > 1) && append) {
        items = items.concat(state.items);
      }
      items = items.concat(items_);

      commit('setItems', { items, total });
    } catch (e) {
      // do nothing
    }

    commit('setFetching', { val: false });
  },

  reset({ commit }) {
    commit('reset');
  }
};
