import { SET_SIZE } from './types';

const mutations = {
  [SET_SIZE](state, w) {
    const breakpoint = 768;
    const breakpointTablet = 992;

    state.w = w;

    if (w < breakpoint) {
      state.isMobile = true;
      state.isTablet = false;
      state.isDesktop = false;
    } else if ((w >= breakpoint) && (w < breakpointTablet)) {
      state.isMobile = false;
      state.isTablet = true;
      state.isDesktop = false;
    } else if (w >= breakpointTablet) {
      state.isMobile = false;
      state.isTablet = false;
      state.isDesktop = true;
    }
  }
};

export default mutations;
