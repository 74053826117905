<template>
  <div id="story-editor">
    <div class="title">
      <font-awesome-icon :icon="faPizzaSlice" class="icon" />
      <h1>{{ t('STORY_EDITOR') }}</h1>
    </div>

    <div class="editor">
      <text-input
        :placeholder="t('TITLE')"
        v-model:value="title"
        required
        :label="t('ENTER_STORY_TITLE')"
        ref="titleInput"
        @blur="autoSlug()"
      />

      <text-input
        :placeholder="t('SLUG')"
        v-model:value="slug"
        required
        :label="t('ENTER_SLUG')"
        ref="slugInput"
      />

      <textarea-input
        rows="4"
        :placeholder="t('DESCRIPTION')"
        v-model:value="description"
        :label="t('ENTER_DESCRIPTION')"
        ref="descriptionInput"
      />

      <file-input
        v-model:value="imageFile"
        :placeholder="t('IMAGE')"
        :label="t('SELECT_IMAGE')"
        ref="imageFileInput"
        v-show="!image"
      />

      <div class="img-holder" v-if="!!image">
        <img :src="image.url" :alt="title" />
        <div class="remove" @click="removeImage()">
          <font-awesome-icon :icon="faTrashAlt" class="icon" />
        </div>
      </div>

      <file-input
        v-model:value="coverImageFile"
        :placeholder="t('COVER_IMAGE')"
        :label="t('SELECT_IMAGE')"
        ref="coverImageFileInput"
        v-show="!coverImage"
      />

      <div class="img-holder" v-if="!!coverImage">
        <img :src="coverImage.url" :alt="title" />
        <div class="remove" @click="removeCoverImage()">
          <font-awesome-icon :icon="faTrashAlt" class="icon" />
        </div>
      </div>

      <file-input
        v-model:value="ebookFile"
        :placeholder="t('EBOOK_FILE')"
        :label="t('SELECT_FILE')"
        ref="ebookFileInput"
        v-show="!ebook"
      />
      <div class="file-url" v-if="!!ebook">
        <small>{{ ebook.url }}</small>
        <div class="remove" @click="removeEbook()">
          <font-awesome-icon :icon="faTrashAlt" class="icon" />
        </div>
      </div>

      <text-input
        v-model:value="formatedPrice"
        :label="t('PRICE')"
        type="money"
        ref="priceInput"
      />

      <text-input
        v-model:value="requiresPurchaseAtIndex"
        :label="t('INDEX')"
        type="integer"
      />

      <toggle-input
        :disabled="!canPublish"
        v-model:value="published"
        :label="t('PUBLISH')"
      />
    </div>

    <primary-button :text="t(storyId ? 'UPDATE' : 'ADD')" @click="submit()" />
    <primary-button :text="t('CANCEL')" @click="cancel()" />
  </div>
</template>

<script>
import { onMounted, ref, watchEffect, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPizzaSlice, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import slugify from 'slugify';

import PATHS from '@/consts/paths';
import PERMISSIONS from '@/consts/permissionCodes';

import TextInput from '@/components/TextInput';
import TextareaInput from '@/components/TextareaInput';
import ToggleInput from '@/components/ToggleInput';
import FileInput from '@/components/FileInput';

import PrimaryButton from '@/components/PrimaryButton';

import { normalizeText, getBase64, addCommas, removeCommas } from '@/helpers';
import { hasPermissions } from '@/helpers/permission';

import StoryEditorService from './StoryEditorService';

export default {
  title: 'StoryEditor',
  components: {
    FontAwesomeIcon,
    TextInput,
    TextareaInput,
    ToggleInput,
    FileInput,
    PrimaryButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const storyId = ref(null);

    const pieces = ref([]);

    const title = ref('');
    const description = ref('');
    const slug = ref('');
    const published = ref(false);
    const imageFile = ref(null);
    const base64Image = ref(null);
    const image = ref(null);
    const coverImageFile = ref(null);
    const base64CoverImage = ref(null);
    const coverImage = ref(null);

    const ebookFile = ref(null);
    const base64Ebook = ref(null);
    const ebook = ref(null);

    const formatedPrice = ref(0);
    const price = computed(() => removeCommas(formatedPrice.value));

    const requiresPurchaseAtIndex = ref(-1);

    const titleInput = ref(null);
    const slugInput = ref(null);
    const piecesInput = ref(null);

    const canPublish = computed(() => hasPermissions([PERMISSIONS.ADMIN_MANAGE_STORY]));

    const autoSlug = () => {
      if (!slug.value) {
        slug.value = slugify(normalizeText(title.value));
      }
    };

    const submit = async () => {
      const inputs = [
        titleInput.value,
        slugInput.value
      ];

      let valid = true;

      inputs.forEach((input) => {
        input.validateInput();
        valid = valid && input.isValid;
      });

      if (valid) {
        const data = {
          title: title.value,
          slug: slug.value,
          description: description.value,
          published: published.value,
          price: price.value,
          requiresPurchaseAtIndex: requiresPurchaseAtIndex.value
        };

        if (image.value?.id) {
          data.image = image.value;
        } else if (imageFile.value) {
          data.image = {
            data: base64Image.value,
            name: imageFile.value.name
          };
        }

        if (coverImage.value?.id) {
          data.coverImage = coverImage.value;
        } else if (coverImageFile.value) {
          data.coverImage = {
            data: base64CoverImage.value,
            name: coverImageFile.value.name
          };
        }

        if (ebook.value?.id) {
          data.ebook = ebook.value;
        } else if (ebookFile.value) {
          data.ebook = {
            data: base64Ebook.value,
            name: ebookFile.value.name
          };
        }

        try {
          if (storyId.value) {
            await StoryEditorService.updateStory(storyId.value, data);
            store.dispatch('flash/info', 'STORY_SUCCESSFULLY_UPDATED');
          } else {
            await StoryEditorService.createStory(data);
            store.dispatch('flash/info', 'STORY_SUCCESSFULLY_CREATED');
          }

          router.push(PATHS.STORIES);
        } catch (e) {
          store.dispatch('flash/error', e.message);
        }
      }
    };

    const cancel = () => {
      router.push(PATHS.STORIES);
    };

    const removeImage = () => {
      image.value = null;
      imageFile.value = null;
    };

    const removeCoverImage = () => {
      coverImage.value = null;
      coverImageFile.value = null;
    };

    const removeEbook = () => {
      ebook.value = null;
      ebookFile.value = null;
    };

    watchEffect(async () => {
      if (!imageFile.value) return;
      base64Image.value = await getBase64(imageFile.value);
      image.value = { url: base64Image.value };
    });

    watchEffect(async () => {
      if (!coverImageFile.value) return;
      base64CoverImage.value = await getBase64(coverImageFile.value);
      coverImage.value = { url: base64CoverImage.value };
    });

    watchEffect(async () => {
      if (!ebookFile.value) return;
      base64Ebook.value = await getBase64(ebookFile.value);
      ebook.value = { url: ebookFile.value.name };
    });

    onMounted(async () => {
      const { params: { id } } = route;

      if (id) {
        try {
          const story = await StoryEditorService.getStory(id);
          title.value = story.title;
          slug.value = story.slug;
          description.value = story.description;
          pieces.value = story.pieces;
          image.value = story.image;
          coverImage.value = story.coverImage;
          ebook.value = story.ebook;
          published.value = story.published;
          formatedPrice.value = addCommas(story.price);
          requiresPurchaseAtIndex.value = story.requiresPurchaseAtIndex;
          storyId.value = id;
        } catch(e) {
          store.dispatch('flash/error', e.message);
          router.push(PATHS.STORIES);
        }
      }
    });

    return {
      t,
      faPizzaSlice,
      pieces,
      title,
      description,
      slug,
      storyId,
      published,
      imageFile,
      coverImageFile,
      ebookFile,
      autoSlug,
      submit,
      cancel,
      titleInput,
      slugInput,
      piecesInput,
      image,
      coverImage,
      ebook,
      faTrashAlt,
      removeImage,
      canPublish,
      removeCoverImage,
      removeEbook,
      formatedPrice,
      requiresPurchaseAtIndex
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

#story-editor {
  .primary-button {
    margin-right: 15px;
  }
}
</style>
