import { createApp } from 'vue';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import 'bootstrap';

import store from './store';
import i18n from './translation';
import { initInterceptors } from './helpers/interceptor';
import router from './router';
import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/scss/main.scss';

gsap.registerPlugin(ScrollToPlugin);

const app = createApp(App);

app.use(store);
app.use(router);
app.use(i18n);
app.mount('#app');

initInterceptors();
