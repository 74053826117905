import HttpMethod from '@/consts/httpMethod';
import { processRequest, processErrorResponse, processSuccessResponse } from '@/helpers';

export default {
  async createStoryPiece(data) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: '/v1/story-pieces',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data });
      const dish = processSuccessResponse(res);
      return dish;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async updateStoryPiece(id, data) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/story-pieces/${id}`,
      method: HttpMethod.PUT
    };

    try {
      const res = await processRequest(ep, { data });
      const dish = processSuccessResponse(res);
      return dish;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getStoryPiece(id) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: `/v1/story-pieces/${id}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      const dish = processSuccessResponse(res);
      return dish;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getStories(q) {
    const ep = {
      baseURL: process.env.VUE_APP_ADMIN_API_ROOT,
      url: '/v1/stories',
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep, { params: { q, pageSize: 10 } });
      const data = processSuccessResponse(res);
      return data;
    } catch(e) {
      processErrorResponse(e.response);
      return false;
    }
  },
};
