<template>
  <button
    class="primary-button button"
    :class="{
      disabled
    }"
    :type="type"
  >
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    text: String,
    type: {
      type: String,
      default: 'button'
    },
    disabled: Boolean
  },
  setup() {
    return {
      onclick,
      onmouseover
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';

.primary-button {
  height: 36px;
  border: 0;
  border-radius: 4px;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 30px;
  color: $white;
  background-color: $blue;
  font-size: 12px;
  font-weight: bold;
  transition: all .2s ease-in-out;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  user-select: none;
  text-transform: capitalize;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $pink;
    transition: all .2s ease-in-out;
    transform: translateX(100%);
  }

  &.disabled {
    color: $grey;
    border-color: $grey;
    pointer-events: none;
    background-color: $alto;
  }

  &:hover {
    &::before {
      transform: translateX(0);
    }
  }

  svg {
    position: relative;
    top: calc(50% - 18px);
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }

  svg, span {
    vertical-align: middle;
  }

  &:active, &:focus {
    outline: none;
  }
}
</style>
