import dishesService from '@/views/Users/UsersService';

export default {
  applyFilters({ commit }, filters) {
    commit('applyFilters', filters);
  },

  async getUsers({ commit, state }) {
    const { items, total } = await dishesService.getUsers(state.filters);
    commit('setItems', { items, total });
  },

  reset({ commit }) {
    commit('reset');
  }
};
