<template>
  <div id="orders-list">
    <div class="title">
      <font-awesome-icon :icon="faInboxFull" class="icon" />
      <h1>{{ t('ORDERS') }}</h1>
    </div>

    <div class="list" ref="listRef">
      <div class="row">
        <div
          class="col col-xs-12 col-md-6 col-lg-4"
          v-for="(col, i) in itemsGrid"
          :key="i"
        >
          <div
            v-for="item in itemsGrid[i]"
            :key="item.id"
            class="item"
            :class="{
              failed: item.status === ORDER_STATUSES.FAILED,
              success: item.status === ORDER_STATUSES.SUCCESS,
              shipped: item.status === ORDER_STATUSES.SHIPPED,
            }"
          >
            <div class="box">
              <div class="controls">
                <font-awesome-icon :icon="faPaperPlane" class="icon" @click="toggleShipmentPopup(item)" />
                <font-awesome-icon :icon="faClipboardCheck" class="icon" @click="completeOrder(item.id)" />
                <font-awesome-icon :icon="faFolderXmark" class="icon" @click="markSpam(item.id)" v-if="canRemove" />
                <font-awesome-icon :icon="faRepeat" class="icon" @click="resendConfirmEmail(item.id)" />
              </div>

              <h3>{{ item.publicId }} | {{ item.status }}</h3>
              <p>
                {{ item.firstName }} {{ item.lastName }}
                <br />
                {{ item.email }}
                <br />
                {{ item.address }}
                <br />
                {{ item.phoneNumber }}
              </p>
              <p>{{ item.notes }}</p>
              <br />
              <p>{{ item.paymentMethod }}</p>
              <p v-if="item.trackingCode"><small><strong><a :href="item.trackingUrl" target="_blank">{{ item.trackingCode }}</a></strong></small></p>
              <br />
              <ul v-for="(lineItem, index) in item.items" :key="index">
                <li>
                  <strong>{{ lineItem.product?.name || lineItem.story?.title }}</strong> x{{ lineItem.quantity }}
                  <br />
                  {{ lineItem.product?.description || lineItem.story?.description }}
                  <br/>
                  <p><strong>{{ addCommas((lineItem.product?.price || lineItem.story?.price || 0) * lineItem.quantity) }} VNĐ</strong></p>
                </li>
              </ul>
              <br />
              <p><strong>{{ addCommas(item.items.reduce((prev, i) => prev + (i.product?.price || i.story?.price || 0) * i.quantity, 0)) }} VNĐ</strong></p>
              <div class="date">{{ moment(item.createdAt).format(`${locale === 'en' ? 'DD MMM, YYYY' : 'DD [tháng] MM, YYYY'} | HH:ss`) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal show-header :title="shipmentPopupTitle" v-model:active="shipmentPopupActive">
      <template #body>
        <text-input :label="t('ENTER_TRACKING_CODE')" v-model:value="trackingCode" />
      </template>
      <template #footer>
        <primary-button :text="t('CANCEL')" @click="toggleShipmentPopup()" />
        <primary-button :text="t('SEND_TRACKING_CODE')" @click="sendTrackingCode()" class="send" />
      </template>
    </modal>
  </div>
</template>

<script>
import { onMounted, ref, computed, onUnmounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import moment from 'moment';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faInboxFull, faPaperPlane, faClipboardCheck, faFolderXmark, faRepeat } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import Modal from '@/components/Modal';
import TextInput from '@/components/TextInput';
import PrimaryButton from '@/components/PrimaryButton';

import { ORDER_STATUSES } from '@/consts';
import PATHS from '@/consts/paths';
import PERMISSIONS from '@/consts/permissionCodes';
import { applyParams, addCommas } from '@/helpers';
import { hasPermissions } from '@/helpers/permission';

import OrdersService from './OrdersService';

export default {
  name: 'OrdersList',
  components: {
    FontAwesomeIcon,
    Modal,
    TextInput,
    PrimaryButton
  },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const targetingItem = ref(null);
    const shipmentPopupActive = ref(false);
    const shipmentPopupTitle = ref('');
    const trackingCode = ref(null);

    const listRef = ref(null);
    const filters = computed(() => store.state.orders.filters);

    const canRemove = computed(() => hasPermissions([PERMISSIONS.ADMIN_MANAGE_ORDER]));

    const calculateItemHeightScore = (item) => {
      let h = 0;
      if (item?.address) h += item.address.length;
      if (item?.items) h += item.items.reduce((prev, i) => prev + (i.product?.description.length || 0) + (i.story?.description.length || 0), 0);

      return h;
    };

    const items = computed(() => store.state.orders.items);
    const itemsGrid = computed(() => {
      let n = 3;
      if (store.state.app.isTablet) {
        n = 2;
      } else if (store.state.app.isMobile) {
        n = 1;
      }

      const stacks = Array(n).fill().map(() => []);
      const stackHeights = Array(n).fill(0);

      for (let i = 0; i < items.value.length; i += 1) {
        const minStack = stackHeights.indexOf(Math.min(...stackHeights));
        stacks[minStack].push({ ...items.value[i] });
        stackHeights[minStack] += calculateItemHeightScore(items.value[i]);
      }

      return stacks;
    });

    const markSpam = async (id) => {
      try {
        await OrdersService.markSpam(id);
        store.dispatch('orders/getOrders', { append: false });
        store.dispatch('flash/info', 'ORDER_SUCCESSFULLY_MOVED_TO_SPAM');
      } catch(e) {
        store.dispatch('flash/error', e.message);
      }
    };

    const completeOrder = async (id) => {
      try {
        await OrdersService.completeOrder(id);
        store.dispatch('orders/getOrders', { append: false });
        store.dispatch('flash/info', 'ORDER_SUCCESSFULLY_COMPLETED');
      } catch(e) {
        store.dispatch('flash/error', e.message);
      }
    };

    const toggleShipmentPopup = (item = null) => {
      targetingItem.value = item;
      shipmentPopupActive.value = !shipmentPopupActive.value;
    };

    const sendTrackingCode = async () => {
      const { id } = targetingItem.value;

      try {
        await OrdersService.sendTrackingCode(id, trackingCode.value);
        toggleShipmentPopup();
        trackingCode.value = null;
        store.dispatch('orders/getOrders', { append: false });
        store.dispatch('flash/info', 'SUCCESSFULLY_SENT_TRACKING_CODE');
      } catch(e) {
        store.dispatch('flash/error', e.message);
      }
    };

    const resendConfirmEmail = async (id) => {
      try {
        await OrdersService.sendConfirmEmail(id);
        store.dispatch('flash/info', 'SUCCESSFULLY_SENT_CONFIRM_EMAIL');
      } catch(e) {
        store.dispatch('flash/error', e.message);
      }
    };

    const watchLoadMore = () => {
      if (!listRef.value) return;

      const y = window.scrollY;
      const h = $(listRef.value).height();

      if (y + 200 > h) {
        store.dispatch('orders/applyFilters', {
          page: filters.value.page + 1
        });
      }
    };

    watch(targetingItem, (val) => {
      shipmentPopupTitle.value = t('SHIPMENT_FOR_ORDER', { orderId: val?.publicId });
    });

    watch(filters, () => {
      store.dispatch('orders/getOrders', { append: false });
    });

    onMounted(() => {
      store.dispatch('orders/getOrders', { append: false });

      $(window).on('scroll', watchLoadMore);
    });

    onUnmounted(() => {
      $(window).off('scroll', watchLoadMore);
    });

    return {
      t,
      faInboxFull,
      faPlus,
      itemsGrid,
      PATHS,
      faPaperPlane,
      faFolderXmark,
      faClipboardCheck,
      faRepeat,
      markSpam,
      applyParams,
      canRemove,
      completeOrder,
      moment,
      locale,
      listRef,
      addCommas,
      ORDER_STATUSES,
      toggleShipmentPopup,
      shipmentPopupActive,
      shipmentPopupTitle,
      trackingCode,
      sendTrackingCode,
      resendConfirmEmail
    };
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

#orders-list {
  .list {
    .item {
      .box {
        .controls {
          .toggle-input {
            .toggle-wrapper {
              &.active {
                background-color: $blue;
              }
            }
          }
        }

        .date {
          color: $grey;
          margin-top: 10px;
          font-size: 12px;
        }
      }

      &.failed, &.success, &.shipped {
        border-radius: 4px;
        color: $white;
        transition: all .2s ease-in-out;
        border: 0;

        .date {
          color: $white;
        }
      }

      &.failed {
        background-color: $pink;

        .controls .icon {
          color: $white;

          &:hover {
            color: $blue;
          }
        }
      }

      &.success {
        background-color: $blue;

        .controls .icon {
          color: $white;

          &:hover {
            color: $pink;
          }
        }
      }

      &.shipped {
        background-color: $orangeYellow;
        color: $black;

        .controls .icon {
          color: $black;

          &:hover {
            color: $pink;
          }
        }

        .date {
          color: $black;
        }
      }
    }
  }

  .primary-button.send {
    background-color: $black;
  }
}
</style>
