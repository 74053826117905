<template>
  <div class="autocomplete-input input">
    <div class="input-wrapper" ref="wrapper">
      <text-input
        :disabled="disabled"
        :label="label"
        :placeholder="placeholder"
        :required="required"
        v-model:value="term"
        icon-position="right"
        @focus="() => toggleActive(true)"
        @keyup="handleKeyUp"
        @input="handleInput"
      >
        <template v-slot:icon>
          <font-awesome-icon class="icon" :icon="faCaretDown" />
        </template>
      </text-input>

      <div class="items-holder" :class="{ active }" ref="holderRef">
        <ul class="items">
          <li
            v-for="(item, i) in filteredItems"
            class="item"
            :class="{ selected: i === selectedItem }"
            :key="item.value"
            @click="select(item.value)"
            ref="itemsRef"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
    </div>

    <div class="selected-items" v-if="multiple">
      <div class="item" v-for="item in itemsList" :key="item">
        <span>{{ getItemText(item) }}</span>
        <div class="remove" @click="select(item)">
          <font-awesome-icon class="icon" :icon="faTimes" />
        </div>
      </div>
    </div>

    <small class="red" v-if="!!validateMessage">{{ validateMessage }}</small>
  </div>
</template>

<script>
import { ref, computed, watchEffect, watch } from 'vue';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from 'vue-i18n';

import { normalizeText } from '@/helpers';

import TextInput from '@/components/TextInput';

let keyupTimeout;

export default {
  name: 'AutocompleteInput',
  props: {
    placeholder: String,
    label: String,
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, String, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: () => ''
    },
    bounce: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    TextInput,
    FontAwesomeIcon
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const term = ref('');
    const active = ref(false);
    const wrapper = ref(null);
    const items = computed(() => props.items);
    const filteredItems = ref([...items.value]);
    const selectedItem = ref(-1);
    const itemsRef = ref(null);
    const holderRef = ref(null);
    const validateMessage = ref('');
    const isValid = ref(false);

    const wrappedValue = computed({
      get() { return props.value; },
      set(val) {
        emit('update:value', val);
      }
    });

    const parseValueToArray = () => {
      let val;
      if (wrappedValue.value) {
        if (typeof wrappedValue.value === 'string') {
          val = [wrappedValue.value];
        } else {
          if (
            (typeof wrappedValue.value === 'object')
            && Object.prototype.hasOwnProperty.call(wrappedValue.value, 'length')
          ) {
            val = wrappedValue.value;
          } else {
            val = [wrappedValue.value];
          }
        }
      } else {
        val = [];
      }

      return val;
    };

    const itemsList = computed(() => parseValueToArray());

    const toggleActive = (val) => {
      if (typeof val === 'boolean') {
        active.value = val;
      } else {
        active.value = !active.value;
      }
    };

    const watchWrapper = (e) => {
      const elem = wrapper.value;
      if (!elem) return;

      let { pageX, pageY } = e;
      let { top, left } = $(elem).offset();
      let width = $(elem).width();
      let height = $(elem).height();

      if ((pageX < left) || (pageX > left + width) || (pageY < top) || (pageY > top + height)) {
        ({ pageX, pageY } = e);
        ({ top, left } = $(elem).offset());
        width = $(elem).outerWidth();
        height = $(elem).outerHeight();

        if ((pageX < left) || (pageX > left + width) || (pageY < top) || (pageY > top + height)) {
          toggleActive(false);
        }
      }
    };

    const getItemText = (val) => {
      const item = props.items.find((i) => i.value === val);
      if (item) {
        return item.text;
      }

      return '';
    };

    const select = (val) => {
      if (props.multiple) {
        if (
          wrappedValue.value
          && (typeof wrappedValue.value === 'object')
          && Object.prototype.hasOwnProperty.call(wrappedValue.value, 'length')
        ) {
          const arr = [...wrappedValue.value];
          const i = arr.findIndex((v) => (v === val));

          if (i < 0) {
            arr.push(val);
            wrappedValue.value = [...arr];
          } else {
            wrappedValue.value = [
              ...arr.slice(0, i),
              ...arr.slice(i + 1, arr.length),
            ];
          }
        } else {
          wrappedValue.value = [val];
        }
      } else {
        wrappedValue.value = val;
      }

      active.value = false;
    };

    const handleInput = (e) => {
      if (keyupTimeout) {
        clearTimeout(keyupTimeout);
      }

      keyupTimeout = setTimeout(() => {
        emit('type', e.target.value);
      }, props.bounce);

      wrappedValue.value = null;
    };

    const handleKeyUp = (e) => {
      const { keyCode } = e;

      if (!itemsRef.value || !itemsRef.value.length) return;

      switch (keyCode) {
        case 38:
          selectedItem.value -= 1;
          if (selectedItem.value < 0) selectedItem.value = 0;

          if (holderRef.value.scrollTop > itemsRef.value[selectedItem.value].offsetTop) {
            holderRef.value.scrollTop = itemsRef.value[selectedItem.value].offsetTop;
          }

          break;

        case 40:
          selectedItem.value += 1;
          if (selectedItem.value > filteredItems.value.length - 1) selectedItem.value = filteredItems.value.length - 1;

          if (holderRef.value.scrollTop + $(holderRef.value).height() - $(itemsRef.value[0]).height() < itemsRef.value[selectedItem.value].offsetTop) {
            holderRef.value.scrollTop = itemsRef.value[selectedItem.value - 1].offsetTop;
          }

          break;

        case 13:
          if ((selectedItem.value >= 0) && (selectedItem.value < filteredItems.value.length)) {
            select(filteredItems.value[selectedItem.value].value);
          }
          break;

        default:
          break;
      }
    };

    const alert = (msg) => {
      validateMessage.value = msg;
    };

    const validateInput = () => {
      const vals = parseValueToArray();

      if (props.required && !vals.length) {
        alert(t('REQUIRED_FIELD'));
        return false;
      }

      const msg = props.validate(wrappedValue.value);

      if (msg) {
        alert(t(msg));
        return false;
      }

      alert('');
      return true;
    };

    const magnetValue = () => {
      if (!props.multiple) {
        term.value = getItemText(wrappedValue.value);
      }
    };

    watchEffect(() => {
      if (active.value) {
        setTimeout(() => {
          $(document.body).on('click', watchWrapper);
        }, 0);
      } else {
        $(document.body).off('click', watchWrapper);
        magnetValue();
      }
    });

    watchEffect(() => {
      let list = [...items.value];

      if (term.value) {
        const filtered = list.filter((item) => normalizeText(item.text).indexOf(normalizeText(term.value)) >= 0);
        if (filtered.length) {
          list = filtered;
        }
      }

      if (props.multiple) {
        const filtered = list.filter((item) => (itemsList.value.indexOf(item.value) < 0));

        if (filtered.length) {
          list = filtered;
        }
      }

      filteredItems.value = list;
      selectedItem.value = -1;

      if (holderRef.value) {
        holderRef.value.scrollTop = 0;
      }
    });

    watchEffect(() => {
      if (wrappedValue.value) {
        term.value = getItemText(wrappedValue.value);
      }
    });

    watch(wrappedValue, () => {
      isValid.value = validateInput();
    });

    return {
      term,
      itemsList,
      active,
      toggleActive,
      wrapper,
      filteredItems,
      handleKeyUp,
      handleInput,
      selectedItem,
      select,
      getItemText,
      faTimes,
      itemsRef,
      holderRef,
      validateInput,
      validateMessage,
      isValid,
      faCaretDown
    };
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/colors';

.autocomplete-input {
  position: relative;

  .items-holder {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    max-height: 0;
    background-color: $white;
    overflow-y: scroll;
    z-index: 9;
    box-shadow:inset 0px 0px 0px 1px $alto;
    transition: all .2s ease-in-out;
    box-sizing: border-box;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      > li {
        padding: 7px 15px;
        transition: all .2s ease-in-out;

        &:hover, &.selected {
          background-color: $blue;
          color: $white;
          cursor: pointer;
        }
      }
    }

    &.active {
      max-height: 200px;
    }
  }

  .selected-items {
    margin-top: 5px;

    .item {
      display: inline-block;
      border: 1px solid $alto;
      border-radius: 20px;
      padding: 3px 7px;
      padding-right: 25px;
      font-size: 12px;
      margin-right: 5px;
      margin-bottom: 5px;
      position: relative;

      .remove {
        position: absolute;
        top: 3.5px;
        right: 9px;
        cursor: pointer;
      }
    }
  }

  small {
    &.red {
      color: $pink;
    }
  }
}
</style>
