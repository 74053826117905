<template>
  <div
    class="cross"
    :class="{
      white
    }"
  >
    <div class="line line-1" />
    <div class="line line-2" />
  </div>
</template>

<script>
export default {
  name: 'Cross',
  props: {
    white: Boolean
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

.cross {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  transform: translateY(10px);

  .line {
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 20px;
      background-color: $black;
      transform-origin: center;
  }

  .line-2 {
      transform: rotate(90deg);
  }

  &.white {
    .line {
      background-color: $white;
    }
  }
}
</style>
